import axios from "axios";
// import qs from 'qs'

// export const logobaseurl = "http://localhost:8000/static";
export const logobaseurl = "https://api-partner.online-united.de/static"

let instance = axios.create({
  baseURL: "https://api-partner.online-united.de/api",
  // baseURL: "http://localhost:8000/api",
});

// request header
instance.interceptors.request.use((config) => {
  // Do something before request is sent

  const token = localStorage.getItem("TOU");
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }  
  return config
}, error => {
  return Promise.reject(error)
})

export default instance
