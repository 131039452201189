import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Modal, Button, Text, Input, Grid, Dropdown } from "@nextui-org/react";
import Task_Service from "../Services/taskService";
import AdminDataservice from "../Services/adminService";

export default function PersonalTask(props) {
  const history = useHistory();
  const advertiserId = props.advertiserId;

  const [visible, setVisible] = useState(false);
  const [taskErr, setTaskErr] = useState("");
  const [taskmessage, setTaskmessage] = useState("");
  const [tasktype, setTaskType] = useState(new Set(["On Selected Date"]));
  const [onweek, setOnweek] = useState(new Set(["All Week"]));
  const [selecteddays, setSelectedDays] = useState(new Set(["Monday"]));
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(new Set(["Select User"]));
  const [description, setDescription] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(
    (e) => {
      AdminDataservice.getuserlist()
        .then((response) => {
          if (response.status === 200) {
            setUserList(response.data.data);
          } else {
            localStorage.removeItem("TOU");
            return history.push("/Unauthorised");
          }
        })
        .catch((err) => {
          if (err.response.status === 403) {
            localStorage.removeItem("TOU");
            return history.push("/Unauthorised");
          }
        });
    },
    [history]
  );

  //   const selectedtasktype = React.useMemo(
  //     () => Array.from(tasktype).concat(),
  //     [tasktype]
  //   );
  const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

  const selectedtasktype = React.useMemo(
    () => Array.from(tasktype).concat(),
    [tasktype]
  );
  const selectedUserId = React.useMemo(
    () => Array.from(selectedUser).concat(),
    [selectedUser]
  );
  const selectedweektype = React.useMemo(
    () => Array.from(onweek).concat(),
    [onweek]
  );

  const selectedDaysList = React.useMemo(
    () => Array.from(selecteddays).concat(),
    [selecteddays]
  );

  const handler = () => setVisible(true);
  const closeHandler = () => {
    setVisible(false);
  };

  const insertPersonalTask = () => {
    if (description === "") {
      setTaskErr("Task Description Required.");
      setTaskmessage("");
    } else if (selectedtasktype[0] !== "Selected Days" && selectedDate === "") {
      setTaskErr("Please Select Proper Date.");
      setTaskmessage("");
    } else if (
      selectedtasktype[0] !== "Selected Days" &&
      new Date(selectedDate) <= new Date()
    ) {
      setTaskErr("Date should be for future only.");
      setTaskmessage("");
    } else if (selectedUserId[0] === "Select User") {
      setTaskErr("Please Assign Task to User.");
      setTaskmessage("");
    } else {
      Task_Service.addNewPersonalTask(
        advertiserId,
        description,
        selectedtasktype[0],
        selectedDate,
        selectedweektype[0],
        selectedDaysList,
        selectedUserId
      )
        .then((response) => {
          if (response.status === 200) {
            setDescription("");
            setTaskType(new Set(["on special date"]));
            setSelectedDays(new Set(["Monday"]));
            setTaskErr("");
            setSelectedDate("");
            setOnweek("All Week");
            setTaskmessage("Task Generated Successfully");
            props.getDatafromPersonalTask(response.data.personaltask);
            closeHandler();
            setTaskmessage("");
            setSelectedUser("Select User");
          } else {
            localStorage.removeItem("TOU");
            return history.push("/Unauthorised");
          }
        })
        .catch((err) => {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        });
    }
  };

  return (
    <div>
      <Button auto color="secondary" onPress={handler}>
        +
      </Button>
      <Modal
        closeButton
        animated={true}
        width={"35rem"}
        blur
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
      >
        <Modal.Header>
          <Text b size={18}>
            Add Personal Task
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Grid>
            <Input
              required="true"
              rounded
              bordered
              fullWidth
              label="Task Description"
              placeholder="Enter Description"
              color="success"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
          <Dropdown>
            <Dropdown.Button flat color="success" css={{ tt: "capitalize" }}>
              {selectedtasktype}
            </Dropdown.Button>

            <Dropdown.Menu
              color="success"
              disallowEmptySelection
              selectionMode="single"
              selectedKeys={tasktype}
              onSelectionChange={setTaskType}
            >
              <Dropdown.Item key="On Special Date">
                On Special Date
              </Dropdown.Item>
              <Dropdown.Item key="Before Special Date">
                Before Special Date
              </Dropdown.Item>
              <Dropdown.Item key="Selected Days">Selected Days</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {selectedtasktype[0] === "Selected Days" ? (
            <Grid.Container>
              <Grid xl={6} xs={6} sm={6} justify="left">
                <Dropdown>
                  <Dropdown.Button
                    flat
                    color="success"
                    css={{ tt: "capitalize" }}
                  >
                    {selectedweektype}
                  </Dropdown.Button>
                  <Dropdown.Menu
                    color="success"
                    disallowEmptySelection
                    selectionMode="single"
                    selectedKeys={onweek}
                    onSelectionChange={setOnweek}
                  >
                    <Dropdown.Item key="All Week">All Week</Dropdown.Item>
                    <Dropdown.Item key="Odd Week">Odd Week</Dropdown.Item>
                    <Dropdown.Item key="Even Week">Even Week</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Grid>
              <Grid xl={6} xs={6} sm={6} justify="right">
                <Dropdown>
                  <Dropdown.Button
                    flat
                    color="success"
                    css={{ tt: "capitalize" }}
                  >
                    Select Days
                  </Dropdown.Button>
                  <Dropdown.Menu
                    aria-label="Multiple selection actions"
                    color="success"
                    disallowEmptySelection
                    selectionMode="multiple"
                    selectedKeys={selecteddays}
                    onSelectionChange={setSelectedDays}
                  >
                    {weekdays.map((ele, index) => {
                      return <Dropdown.Item key={ele}>{ele}</Dropdown.Item>;
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Grid>
            </Grid.Container>
          ) : (
            <Grid>
              <Input
                fullWidth
                label="Select Special Date"
                type="date"
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </Grid>
          )}
          <Dropdown>
            <Dropdown.Button flat color="success" css={{ tt: "capitalize" }}>
              {selectedUser}
            </Dropdown.Button>

            <Dropdown.Menu
              color="success"
              disallowEmptySelection
              css={{ maxWidth: "fit-content" }}
              selectionMode="single"
              selectedKeys={selectedUser}
              onSelectionChange={setSelectedUser}
            >
              <Dropdown.Item key="Select User">Select User</Dropdown.Item>
              <Dropdown.Item key="matthias.feifel@online-united.de">
                matthias.feifel@online-united.de
              </Dropdown.Item>

              {userList.map((user, index) =>
                user.isActive ? (
                  <Dropdown.Item key={user.username}>
                    {user.username}
                  </Dropdown.Item>
                ) : null
              )}
            </Dropdown.Menu>
          </Dropdown>
          {taskErr ? <Text color="error">{taskErr}</Text> : ""}
          {taskmessage ? <Text color="success">{taskmessage}</Text> : ""}
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat color="error" onPress={closeHandler}>
            Close
          </Button>
          <Button auto onPress={insertPersonalTask}>
            Generate Task
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
