import http from "./Http-Common";

class BrevoDataService {
  uploadcontacts(contact) {
    return http.post("/brevo/uploadcontact", { contact });
  }

  uploadawinpublishercsv(advertiserId, awinpublisher) {
    return http.post(`/brevo/awin/publishercsv/${advertiserId}`, {
      awinpublisher,
    });
  }
}

export default new BrevoDataService();
