import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Card,
  Grid,
  Text,
  Button,
  Table,
  Checkbox,
  Modal,
  Input,
  Spacer,
  Dropdown,
} from "@nextui-org/react";
import ReactCountryFlag from "react-country-flag";

import moment from "moment";
import Adv_Pub_DataService from "../Services/advertiserService";
import Task_Service from "../Services/taskService";

import Navbar from "./Navigationbar";
import { logobaseurl } from "../Services/Http-Common";

function Homepage(props) {
  const history = useHistory();

  const embedUrl =
    "https://app.powerbi.com/view?r=eyJrIjoiMzhkY2Y3OTUtY2I0MS00ODAzLTk0NTEtMGE1OTAwYzllODdiIiwidCI6ImQ5ZTE0YzFkLTA2MzAtNGExYS04ZWU5LTExZDVlYTQ1NzA3ZCJ9"; // Replace {value} with your report ID or other parameters

  const publisher_bi_report =
    "https://app.powerbi.com/view?r=eyJrIjoiOTM1YzVjZmMtNjJlOC00ZjY2LTgxNjgtNWIyMDEyM2VmMGNlIiwidCI6ImQ5ZTE0YzFkLTA2MzAtNGExYS04ZWU5LTExZDVlYTQ1NzA3ZCJ9";

  const [superpowerbutton, setSuperpowerButton] = useState(false);
  const [checkedSuperButton, setCheckedSuperButton] = useState(false);
  const [weeklyoverviewtask, setWeeklyOverview] = useState([]);

  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  const [month, setMonth] = useState(
    moment().subtract(1, "months").startOf("month").format("YYYY-MM")
  );

  const [approvedturnovercommissionarray, setApprovedTurnoverCommissionArray] =
    useState([]);
  const [error, setError] = useState("");

  const [visibleturnovercommission, setVisibleturnovercommission] =
    useState(false);

  const [advertiserArray, setAdvertiserArray] = useState([]);
  const [todaystask, setTodaysTask] = useState([]);

  useEffect(
    (e) => {
      Adv_Pub_DataService.getAllActiveAdvertiser(null)
        .then((response) => {
          if (response.status === 200) {
            setAdvertiserArray(response.data.data.data);

            setWeeklyOverview(response.data.data.weeklyoverviewtask);
            setTodaysTask(response.data.data.todaystask);
            setSuperpowerButton(response.data.data.issuperpower);
          } else {
            localStorage.removeItem("TOU");
            return history.push("/Unauthorised");
          }
        })
        .catch((err) => {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        });
    },
    [history]
  );

  let columns = [{ name: "Advertisers", uid: "Advertisers" }];

  weeklyoverviewtask.forEach((task) => {
    columns.push({ name: task.task_description, uid: task.task_description });
  });

  const getAllAdvertiser = (superpowerbuttonstatus) => {
    Adv_Pub_DataService.getAllActiveAdvertiser(superpowerbuttonstatus)
      .then((response) => {
        setAdvertiserArray([]);
        if (response.status === 200) {
          setAdvertiserArray(response.data.data.data);
          setSuperpowerButton(response.data.data.issuperpower);
          setCheckedSuperButton(!checkedSuperButton);
          // setWeeklyOverview(response.data.data.weeklyoverviewtask);
        } else {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        }
      })
      .catch((err) => {
        localStorage.removeItem("TOU");
        return history.push("/Unauthorised");
      });
  };

  const handletaskdone = (isdone, taskId, tasktype, advertiserId) => {
    Task_Service.update_task_done(isdone, taskId, advertiserId, tasktype)
      .then((response) => {
        if (response.status === 200) {
          const index = weeklyoverviewtask.findIndex(function (i) {
            return i._id === taskId;
          });
          if (index >= 0) {
            const advindex = weeklyoverviewtask[index].advertisers.findIndex(
              function (i) {
                return i.advertiserId === advertiserId;
              }
            );
            if (advindex >= 0) {
              weeklyoverviewtask[index].advertisers[advindex].isdone = isdone;
            }
          }

          const updatedweektaskview = weeklyoverviewtask;
          setWeeklyOverview(updatedweektaskview);
        } else {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        }
      })
      .catch((err) => {
        localStorage.removeItem("TOU");
        return history.push("/Unauthorised");
      });
  };

  const handlerturnovercommission = () => setVisibleturnovercommission(true);
  const closeHandlerturnovercommission = () => {
    setVisibleturnovercommission(false);
  };

  const calculatePerformanceFees = (data) => {
    return data.base === "Turnover"
      ? (data.turnover * data.percentage) / 100
      : (data.commission * data.percentage) / 100;
  };

  const calculateTotal = (data) => {
    if (data.fix !== 0) {
      return calculatePerformanceFees(data) + data.fix;
    } else if (data.min !== 0) {
      return calculatePerformanceFees(data) <= data.min
        ? data.min
        : calculatePerformanceFees(data);
    } else {
      return calculatePerformanceFees(data);
    }
  };

  const sortapprovedturnovercommissionarray = (type) => {
    let sortedapprovedturnovercommissionarray = [
      ...approvedturnovercommissionarray,
    ];

    if (type === "advertiser") {
      sortedapprovedturnovercommissionarray = [
        ...approvedturnovercommissionarray,
      ].sort((a, b) => {
        return a.advertiserName.localeCompare(b.advertiserName);
      });
    } else if (type === "sales") {
      sortedapprovedturnovercommissionarray = [
        ...approvedturnovercommissionarray,
      ].sort((a, b) => b.sales - a.sales);
    } else if (type === "turnover") {
      sortedapprovedturnovercommissionarray = [
        ...approvedturnovercommissionarray,
      ].sort((a, b) => b.turnover - a.turnover);
    } else if (type === "commission") {
      sortedapprovedturnovercommissionarray = [
        ...approvedturnovercommissionarray,
      ].sort((a, b) => b.commission - a.commission);
    } else if (type === "performancefees") {
      sortedapprovedturnovercommissionarray = [
        ...approvedturnovercommissionarray,
      ].sort(
        (a, b) => calculatePerformanceFees(b) - calculatePerformanceFees(a)
      );
    } else if (type === "fixed") {
      sortedapprovedturnovercommissionarray = [
        ...approvedturnovercommissionarray,
      ].sort((a, b) => b.fix - a.fix);
    } else if (type === "total") {
      sortedapprovedturnovercommissionarray = [
        ...approvedturnovercommissionarray,
      ].sort((a, b) => calculateTotal(b) - calculateTotal(a));
    }
    setApprovedTurnoverCommissionArray(sortedapprovedturnovercommissionarray);
  };

  const handleCalculateturnover_Commission = async () => {
    try {
      if (!month) {
        setError("Select valid Month.");
        return;
      }
      setError("Fetching data..!!");
      const startDate = moment(month).startOf("month").format("YYYY-MM-DD");
      const endDate = moment(startDate)
        .add(1, "M")
        .startOf("month")
        .format("YYYY-MM-DD");
      await Adv_Pub_DataService.getturnoverCommision(startDate, endDate).then(
        (response) => {
          if (response.data?.data?.length <= 0) {
            setError("No data Found !!");
          } else {
            setError("");
            setApprovedTurnoverCommissionArray(response.data?.data);
          }
        }
      );
    } catch (error) {
      console.log(error);
      setError("Something went wrong!!");
    }
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <Navbar />
      <Grid.Container gap={2}>
        <Grid xl={8} xs={15} sm={6} justify="left">
          <Grid>
            <button
              type="button"
              class="btn btn-outline-dark"
              onClick={(e) => handlerturnovercommission(true)}
            >
              Approved Turnover & Commission
            </button>
          </Grid>

          <Grid>
            <PublisherComission
              uniqueNetworks={[
                ...new Set(advertiserArray.map((item) => item.network)),
              ]}
            />
          </Grid>
        </Grid>
        <Grid xl={3} xs={20} sm={4} justify="right">
          <a
            href={publisher_bi_report}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button type="button" class="btn btn-outline-dark">
              ALL - Weekly
            </button>
          </a>
        </Grid>
        <Grid justify="right">
          <Link
            to={{
              pathname: "/adv_pub",
              state: {
                startDate: moment().startOf("year").format("YYYY-MM-DD"),
                endDate: moment().endOf("year").format("YYYY-MM-DD"),
                advertiserId: "All",
                advertiserName: "All Advertisers",
              },
            }}
          >
            <button type="button" class="btn btn-outline-dark">
              All Publisher - Report
            </button>
          </Link>
        </Grid>
        {/* MODAL FOR TURNOVER and COMMISSION */}
        <Modal
          closeButton
          preventClose
          aria-labelledby="modal-title"
          open={visibleturnovercommission}
          onClose={closeHandlerturnovercommission}
          width="70rem"
        >
          <Modal.Body>
            <h2 style={{ alignItems: "center" }}>
              Approved Turnover & Commission
            </h2>

            <div className="form-group">
              <label htmlFor="startDate">Start Date:</label>
              <input
                type="month"
                id="startDate"
                className="form-control"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
              />
            </div>
            <Button auto onClick={handleCalculateturnover_Commission}>
              Calculate
            </Button>
            <hr />
            {!error && approvedturnovercommissionarray.length > 0 ? (
              <>
                <table className="table table-striped text-right">
                  <tr>
                    <th
                      scope="col"
                      onClick={(e) =>
                        sortapprovedturnovercommissionarray("advertiser")
                      }
                    >
                      Advertiser
                    </th>
                    <th
                      scope="col"
                      style={{ borderLeft: "1px solid black" }}
                      onClick={(e) =>
                        sortapprovedturnovercommissionarray("sales")
                      }
                    >
                      Sales
                    </th>
                    <th
                      scope="col"
                      onClick={(e) =>
                        sortapprovedturnovercommissionarray("turnover")
                      }
                    >
                      Turnover
                    </th>
                    <th
                      scope="col"
                      onClick={(e) =>
                        sortapprovedturnovercommissionarray("commission")
                      }
                    >
                      Commission
                    </th>
                    <th
                      scope="col"
                      style={{ borderLeft: "1px solid black" }}
                      onClick={(e) =>
                        sortapprovedturnovercommissionarray("performancefees")
                      }
                    >
                      Performance
                    </th>
                    <th
                      scope="col"
                      className="green-bg"
                      onClick={(e) =>
                        sortapprovedturnovercommissionarray("fixed")
                      }
                    >
                      Fixed Fees
                    </th>
                    <th
                      scope="col"
                      className="green-bg"
                      onClick={(e) =>
                        sortapprovedturnovercommissionarray("total")
                      }
                    >
                      Total
                    </th>
                  </tr>
                  <tbody>
                    {approvedturnovercommissionarray.map((data, index) => {
                      return (
                        <tr key={index} style={{ cursor: "pointer" }}>
                          <th>{data.advertiserName.toUpperCase()}</th>
                          <td style={{ borderLeft: "1px solid black" }}>
                            {data.sales}
                          </td>
                          <td>
                            {new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(data.turnover)}
                          </td>
                          <td>
                            {new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(data.commission)}
                          </td>
                          <td style={{ borderLeft: "1px solid black" }}>
                            {new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(calculatePerformanceFees(data))}
                          </td>
                          <td>
                            {new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(data.fix)}
                          </td>
                          <th>
                            {new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(calculateTotal(data))}
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <h6 style={{ color: "red" }}>{error}</h6>
              </>
            )}
          </Modal.Body>
        </Modal>
      </Grid.Container>

      {/* <div style={{ backgroundColor: "lightgrey", margin: "2rem " }}>
        <Grid.Container gap={2.5} justify="center">
          {advertiserArray.map((ele, index) => {
            return (
              <Grid xl={3} key={index}>
                <Card
                  isPressable
                  isHoverable
                  variant="bordered"
                  css={{
                    mw: "400px",
                    bgColor: !ele.isYesterdayActive ? "LightPink" : "White",
                  }}
                >
                  <Card.Header>
                    <Grid xl={11} sm={11}>
                      <Text b>{ele.advertiserName}</Text>
                    </Grid>
                    <Grid xl={5} sm={5}>
                      <ReactCountryFlag
                        countryCode={ele.advertiserName.split(" ").pop()}
                        svg
                        style={{
                          width: "2em",
                          height: "2em",
                        }}
                        title="US"
                      />
                    </Grid>

                    <Grid xl={20} sm={20}>
                      {ele.logourl ? (
                        <img src={logobaseurl + ele.logourl} alt="logo img" />
                      ) : (
                        "NO LOGO"
                      )}
                    </Grid>
                  </Card.Header>
                  <Card.Body>
                    <Grid.Container gap={2}>
                      <Grid xl={6} sm={6}>
                        <Input
                          width="100px"
                          label="Start Date"
                          type="date"
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Grid>
                      <Grid xl={5} sm={5}>
                        <Input
                          width="100px"
                          label="End Date"
                          type="date"
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Grid>
                      <Grid justify="center" xl>
                        <Link
                          to={{
                            pathname: "/adv_pub",
                            state: {
                              startDate:
                                startDate !== ""
                                  ? startDate
                                  : moment()
                                      .startOf("year")
                                      .format("YYYY-MM-DD"),
                              endDate:
                                endDate !== ""
                                  ? endDate
                                  : moment().endOf("year").format("YYYY-MM-DD"),
                              advertiserId: ele.advertiserId,
                              advertiserName: ele.advertiserName,
                            },
                          }}
                        >
                          <button type="button" class="btn btn-outline-dark">
                            Search Publisher
                          </button>
                        </Link>
                      </Grid>
                    </Grid.Container>

                    <Grid xl={15} sm={15}>
                      <Grid xl={3} sm={3}>
                        <a
                          href={
                            "https://ui.awin.com/awin/merchant/" +
                            ele.advertiserId +
                            "/affiliate-approval"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button bordered color="gradient" auto>
                            Publisherfreigaben
                          </Button>
                        </a>
                      </Grid>
                      <Grid xl={2.5} sm={2.5}></Grid>
                      <Grid xl={5} sm={5}>
                        <a
                          href={
                            "https://ui.awin.com/merchant/" +
                            ele.advertiserId +
                            "/report/performance-over-time/index/network/awin/dateRange/thisMonth?dateType=validation"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button bordered color="gradient" auto>
                            Freigaben aktueller Monat
                          </Button>
                        </a>
                      </Grid>
                    </Grid>

                    <Spacer y={-2} />

                    <Grid xl={15} sm={15}>
                      <Grid xl={3} sm={3}>
                        <Link
                          to={
                            "/advertiserservice?id=" +
                            ele.advertiserId +
                            "&token=" +
                            ele.token
                          }
                          target="_blank"
                        >
                          <Button bordered color="gradient" auto>
                            Generate Report
                          </Button>
                        </Link>
                      </Grid>
                      <Grid xl={2.5} sm={2.5}></Grid>
                      <Grid xl={3} sm={3}>
                        <a
                          href={
                            "https://ui.awin.com/merchant/" +
                            ele.advertiserId +
                            "/report/transactions/export/network/awin/dateType/transaction/transactionType/pending/perPage/10/page/1/timezone/Europe-Paris/reportName/transactions/merchantId/" +
                            ele.advertiserId +
                            "/transactionState/pending/pageNumber/1/reportIdentifier/transactionsReport/dynamicDateRange/custom/start/2021-01-01/end/" +
                            moment()
                              .subtract(ele.daysback, "days")
                              .format("YYYY-MM-DD") +
                            "/hideBonusTransactions/1/amended/0/exportType/excel"
                          }
                        >
                          <Button bordered color="gradient" auto>
                            Generate Excel-Sheet
                          </Button>
                        </a>
                      </Grid>
                    </Grid>
                  </Card.Body>
                </Card>
              </Grid>
            );
          })}
        </Grid.Container>
        <div style={{ margin: "2rem" }}>
          <Grid.Container justify="center">
            {superpowerbutton ? (
              !checkedSuperButton ? (
                <Button
                  shadow
                  auto
                  color="primary"
                  rounded
                  bordered
                  onClick={(e) => getAllAdvertiser("show")}
                >
                  See All Advertiser
                </Button>
              ) : (
                <Button
                  shadow
                  auto
                  color="primary"
                  rounded
                  bordered
                  onClick={(e) => getAllAdvertiser("hide")}
                >
                  Hide Other Advertiser
                </Button>
              )
            ) : (
              <></>
            )}
          </Grid.Container>
        </div>
      </div> */}
      <div style={{ padding: "2rem" }}>
        <Grid.Container justify="center" css={{ paddingBottom: "$10" }}>
          <Grid justify="center">
            <Text h1 size={40} weight="bold">
              Today's Task
            </Text>
          </Grid>
        </Grid.Container>
        <Grid.Container>
          <Card isHoverable css={{ bgColor: "#04999e" }}>
            <Card.Body
              css={{
                fontWeight: "$semibold",
              }}
            >
              <ol style={{ color: "white" }}>
                {todaystask.map((item, index) => {
                  return (
                    <li key={index + 1}>
                      <Grid.Container>
                        <Grid xl={6} xs={6} sm={6} justify="left">
                          {item.advertiserName} -:- {item.task_description}
                        </Grid>
                        <Grid xl={6} xs={6} sm={6} justify="right">
                          {item.tasktype === "Before Special Date"
                            ? "Deadline :: " +
                              moment(item.specialdate).format("DD-MM-YYYY")
                            : "TODAY"}
                        </Grid>
                      </Grid.Container>
                    </li>
                  );
                })}
              </ol>
            </Card.Body>
          </Card>
        </Grid.Container>
      </div>
      {/* Table for task weekly overview */}

      <div style={{ width: "100%", height: "100vh" }}>
        <iframe
          style={{ width: "100%", height: "100%", border: "none" }}
          src={embedUrl}
          allowFullScreen="true"
          title="Power BI Report"
        ></iframe>
      </div>

      <Table
        bordered
        aria-label="Example table with dynamic content & infinity pagination"
        css={{
          minWidth: "100%",
          height: "calc($space$20 * 10)",
          overflow: "auto",
          display: "block",
          paddingTop: "$0",
          marginTop: "$1",
        }}
        color="secondary"
      >
        <Table.Header columns={columns}>
          {(column) => (
            <Table.Column
              key={column.uid}
              css={{
                position: "sticky",
                top: "$0",
                zIndex: "$max",
                width: "10%",
              }}
            >
              {column.name}
            </Table.Column>
          )}
        </Table.Header>
        <Table.Body>
          {advertiserArray.map((adv, advindex) => {
            return (
              <Table.Row key={advindex}>
                <Table.Cell key={advindex}>
                  {/* <ReactCountryFlag
                    countryCode={adv.advertiserName.split(" ").pop()}
                    svg
                    style={{
                      width: "2em",
                      height: "2em",
                    }}
                    title="US"
                  />{" "}
                  {adv.advertiserName} */}
                  <Card
                    isPressable
                    isHoverable
                    variant="bordered"
                    css={{
                      w: "400px",

                      bgColor: !adv.isYesterdayActive ? "LightPink" : "White",
                    }}
                  >
                    <Link
                      to={{
                        pathname: "/advertiser",
                        state: {
                          //   _id: adv._id,
                          //   advertiserId: adv.advertiserId,
                          //   advertiserName: adv.advertiserName,
                          //   logourl: adv.logourl,
                          //   daysback: adv.daysback,
                          //   token: adv.token,
                          //   network: adv.network,
                          advertiserArray: advertiserArray,
                          //   startdate: adv.startdate,
                          //   standardcommission: adv.standardcommission,
                          //   promotionalCodes: adv.promotionalCodes,
                          //   generalinformation: adv.generalinformation,
                        },
                        search: `Id=${adv._id}`,
                      }}
                    >
                      <Card.Header>
                        <Grid xl={11} xs={6}>
                          <ReactCountryFlag
                            countryCode={adv.advertiserName.split(" ").pop()}
                            svg
                            style={{
                              width: "2em",
                              height: "2em",
                            }}
                          />

                          <Text weight="bold" css={{ fontFamily: "Segoe UI" }}>
                            {adv.advertiserName}
                          </Text>
                        </Grid>
                        <Grid xl={5} xs={5} sm={5}></Grid>

                        <Grid xl={20} xs={20}>
                          {adv.logourl ? (
                            <img
                              src={logobaseurl + adv.logourl}
                              alt="logo img"
                            />
                          ) : (
                            "NO LOGO"
                          )}
                        </Grid>
                      </Card.Header>
                    </Link>
                  </Card>
                </Table.Cell>

                {weeklyoverviewtask.map((task, index) => {
                  return (
                    <Table.Cell key={index}>
                      {task.advertisers.some(
                        (x) => x.advertiserId === adv.advertiserId
                      ) ? (
                        <Checkbox
                          color="gradient"
                          defaultSelected={
                            task.advertisers.some(
                              (x) =>
                                x.advertiserId === adv.advertiserId &&
                                x.isdone === true
                            )
                              ? true
                              : false
                          }
                          onChange={(value) =>
                            handletaskdone(
                              value,
                              task._id,
                              task.tasktype,
                              Number(adv.advertiserId)
                            )
                          }
                        ></Checkbox>
                      ) : (
                        <Text color="grey">X</Text>
                      )}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>

      {/* <div style={{ margin: "2rem" }}>
        <Card>
          <Card.Body css={{ bgColor: "#ededed" }}>
            <Grid.Container gap={2} justify="center">
              {advertiserArray.map((ele, index) => {
                return (
                  <Grid xl={2} xs={6} sm={3} key={index} justify="center">
                    <Card
                      isPressable
                      isHoverable
                      variant="bordered"
                      css={{
                        w: "400px",

                        bgColor: !ele.isYesterdayActive ? "LightPink" : "White",
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/advertiser",
                          state: {
                            advertiserId: ele.advertiserId,
                            advertiserName: ele.advertiserName,
                            logourl: ele.logourl,
                            daysback: ele.daysback,
                            token: ele.token,
                          },
                        }}
                      >
                        <Card.Header>
                          <Grid xl={11} xs={6}>
                            <Text
                              weight="bold"
                              css={{ fontFamily: "Segoe UI" }}
                            >
                              {ele.advertiserName}
                            </Text>
                          </Grid>
                          <Grid xl={5} xs={5} sm={5}>
                            <ReactCountryFlag
                              countryCode={ele.advertiserName.split(" ").pop()}
                              svg
                              style={{
                                width: "2em",
                                height: "2em",
                              }}
                              title="US"
                            />
                          </Grid>

                          <Grid xl={20} xs={20}>
                            {ele.logourl ? (
                              <img
                                src={logobaseurl + ele.logourl}
                                alt="logo img"
                              />
                            ) : (
                              "NO LOGO"
                            )}
                          </Grid>
                        </Card.Header>
                      </Link>
                    </Card>
                  </Grid>
                );
              })}
            </Grid.Container>
          </Card.Body>
        </Card>
      </div> */}
      <div style={{ margin: "2rem" }}>
        <Grid.Container justify="center">
          {superpowerbutton ? (
            !checkedSuperButton ? (
              <Button
                shadow
                auto
                color="primary"
                rounded
                bordered
                onClick={(e) => getAllAdvertiser("show")}
              >
                See All Advertiser
              </Button>
            ) : (
              <Button
                shadow
                auto
                color="primary"
                rounded
                bordered
                onClick={(e) => getAllAdvertiser("hide")}
              >
                Hide Other Advertiser
              </Button>
            )
          ) : (
            <></>
          )}
        </Grid.Container>
      </div>
    </div>
  );

  function PublisherComission(props) {
    const [visible, setVisible] = React.useState(false);
    const history = useHistory();

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [publisherId, setPublisherId] = useState(null);
    const [warningmsg, setWarningMsg] = useState();
    const [amount, setAmount] = useState(null);
    const [network, setNetwork] = useState(new Set(["awin"]));
    const allnetwork = props.uniqueNetworks;
    const handler = () => setVisible(true);

    const selectednetwork = React.useMemo(
      () => Array.from(network).concat(),
      [network]
    );

    const calculateCommission = (publisherId, startDate, endDate, selectednetwork) => {
      if (publisherId === null || !publisherId) {
        setAmount(null);
        setWarningMsg("Please Enter Publisher ID.");
      } else if (startDate === "" || endDate === "") {
        setAmount(null);
        setWarningMsg("Please provide Start date and End date both");
      } else if (
        publisherId !== null &&
        new Date(startDate) <= new Date(endDate)
      ) {
        setWarningMsg("");
        Adv_Pub_DataService.countpublishercommission(
          publisherId,
          startDate,
          endDate,
          selectednetwork[0]
        )
          .then((response) => {
            if (response.status === 200) {
              setWarningMsg(response.data.warningmessage);
              setAmount(response.data.amount);
            } else {
              localStorage.removeItem("TOU");
              return history.push("/Unauthorised");
            }
          })
          .catch((err) => {
            localStorage.removeItem("TOU");
            return history.push("/Unauthorised");
          });
      } else {
        setAmount(null);
        setWarningMsg("End date can not be before Start date.");
      }
    };

    return (
      <div>
        <button type="button" class="btn btn-outline-dark" onClick={handler}>
          Publisher Commision
        </button>
        <Modal
          closeButton
          blur
          aria-labelledby="modal-title"
          open={visible}
          onClose={(e) => setVisible(false)}
        >
          <Modal.Header>
            <Text b size={18}>
              Publisher Commission
            </Text>
          </Modal.Header>
          <Modal.Body>
            <Grid.Container gap={2}>
              <Grid>
                <Input
                  status="secondary"
                  bordered
                  label="Publisher*"
                  width="20rem"
                  placeholder="ID"
                  type="Number"
                  value={publisherId}
                  onChange={(e) => setPublisherId(e.target.value)}
                />
              </Grid>

              <Grid xl={7.5} xs={10} sm={7.5}>
                <Input
                  status="secondary"
                  width="100px"
                  label="Start Date"
                  value={startDate}
                  type="date"
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Grid>
              <Spacer x={-2.5}></Spacer>
              <Grid xl={2} xs={10} sm={5}>
                <Input
                  status="secondary"
                  width="100px"
                  label="End Date"
                  value={endDate}
                  type="date"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Grid>
              <Grid xl={5} xs={10} sm={5}>
                <Dropdown css={{ width: "25rem" }}>
                  <Dropdown.Button
                    flat
                    color="success"
                    css={{ tt: "capitalize" }}
                  >
                    {selectednetwork}
                  </Dropdown.Button>
                  <Dropdown.Menu
                    color="success"
                    disallowEmptySelection
                    selectionMode="single"
                    selectedKeys={network}
                    onSelectionChange={setNetwork}
                  >
                    {allnetwork.map((ele, index) => {
                      return (
                        <Dropdown.Item key={ele} css={{ maxWidth: "25rem" }}>
                          {ele}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Grid>
              <Grid>
                {amount != null ? (
                  <Input
                    status="success"
                    readOnly
                    width="20rem"
                    value={
                      "Amount--  " +
                      new Intl.NumberFormat("de-DE", {
                        style: "currency",
                        currency: "EUR",
                      }).format(amount)
                    }
                  />
                ) : (
                  <></>
                )}
              </Grid>
            </Grid.Container>
            {warningmsg ? <Text color="error">{warningmsg}</Text> : <></>}
          </Modal.Body>
          <Modal.Footer>
            <Button auto flat color="error" onClick={(e) => setVisible(false)}>
              Close
            </Button>
            <Button
              auto
              onClick={(e) =>
                calculateCommission(publisherId, startDate, endDate, selectednetwork)
              }
            >
              Calculate
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Homepage;