import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Card,
  Grid,
  Modal,
  Text,
  Dropdown,
  Button,
  Input,
  Switch,
  Spacer,
} from "@nextui-org/react";
// import ReactCountryFlag from "react-country-flag"

// import moment from 'moment'
// import {AiTwotoneAlert} from "react-icons/ai"

import Adv_Pub_DataService from "../Services/advertiserService";
import Navbar from "./Navigationbar";
import AddTask from "./Addtask";
import { logobaseurl } from "../Services/Http-Common";

function Control(props) {
  const history = useHistory();

  const [advertiserArray, setAdvertiserArray] = useState([]);

  useEffect(
    (e) => {
      Adv_Pub_DataService.getAllAdvertiser()
        .then((response) => {
          if (response.status === 200) {
            setAdvertiserArray(response.data.data);
          } else {
            localStorage.removeItem("TOU");
            return history.push("/Unauthorised");
          }
        })
        .catch((err) => {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        });
    },
    [history]
  );

  const advertiserhandleEnd = ({ source, destination }) => {
    if (!destination) {
      return;
    }
    const newItems = [...advertiserArray];
    const [reorderedItem] = newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, reorderedItem);
    setAdvertiserArray(newItems);

    Adv_Pub_DataService.updatepriority(
      advertiserArray[source.index].advertiserId,
      source.index,
      destination.index
    );
  };

  const sendAdvertiserData = (values) => {
    if (values.isedited === true) {
      Adv_Pub_DataService.getAllAdvertiser()
        .then((response) => {
          if (response.status === 200) {
            setAdvertiserArray(response.data.data);
          } else {
            localStorage.removeItem("TOU");
            return history.push("/Unauthorised");
          }
        })
        .catch((err) => {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        });
    }
  };

  const handleactivestate = (state, adv_id) => {
    const value = { isActive: state, advertiserId: adv_id };
    Adv_Pub_DataService.editAdvertiserState(value)
      .then((response) => {
        if (response.status !== 200) {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        }
      })
      .catch((err) => {
        localStorage.removeItem("TOU");
        return history.push("/Unauthorised");
      });
  };

  return (
    <div style={{ backgroundColor: "lightgrey" }}>
      <Navbar />
      {/* <div className="card-body" >
            <h5 className="card-title" style={{ alignItems: "center" }}>
              ADVERTISERS
            </h5>
            
          </div> */}

      <div style={{ margin: "2rem" }}>
        <Grid.Container gap={2} justify="left">
          <Grid xs={7} xl={7} sm={7}>
            <AddTask advertiserArray={advertiserArray} />
          </Grid>

          <Grid xs={5} xl={5} sm={5} justify="right">
            <PublisherComission />
          </Grid>
        </Grid.Container>
      </div>

      <DragDropContext onDragEnd={advertiserhandleEnd}>
        <Droppable droppableId="advertiser-array">
          {(provided) => (
            <Grid.Container
              gap={2.5}
              justify="center"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {advertiserArray.map((ele, index) => (
                <Draggable
                  key={ele.advertiserId}
                  draggableId={ele.advertiserId.toString()}
                  index={index}
                >
                  {(provided) => (
                    <Grid xl={2} sm={3} key={index} justify="center">
                      <Card
                        isHoverable
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        variant="bordered"
                        css={{
                          mw: "400px",
                          bgColor: !ele.isYesterdayActive
                            ? "LightPink"
                            : "White",
                        }}
                      >
                        <Card.Header>
                          <Grid xl={11} sm={11}>
                            <Text b>{ele.advertiserName}</Text>
                          </Grid>
                          {/* <ReactCountryFlag
                              countryCode={ele.advertiserName.split(' ').pop()}
                              svg
                              style={{
                                  width: '2em',
                                  height: '2em',
                              }}
                              title="US"
                          /> */}

                          <Grid xl={20} sm={20}>
                            {ele.logourl ? (
                              <img
                                src={logobaseurl + ele.logourl}
                                alt="logo img"
                              />
                            ) : (
                              "NO LOGO"
                            )}
                          </Grid>
                        </Card.Header>
                        <Card.Body>
                          <Grid xl={15} sm={15}>
                            <Grid xl={3} sm={3}>
                              <Switch
                                size="sm"
                                shadow
                                color="success"
                                checked={ele.isActive}
                                onChange={(e) =>
                                  handleactivestate(
                                    e.target.checked,
                                    ele.advertiserId
                                  )
                                }
                              />
                            </Grid>
                            <Grid xl={6} sm={6}></Grid>
                            <Grid xl={3} sm={3}>
                              <Editbutton
                                sendAdvertiserData={sendAdvertiserData}
                                advertiserId={ele.advertiserId}
                                advertiserName={ele.advertiserName}
                                daysback={ele.daysback}
                                fix={ele.fix}
                                min={ele.min}
                                percentage={ele.percentage}
                                base={ele.base}
                                x_range={ele.x_range}
                                y_range={ele.y_range}
                                netwerkfee={ele.netwerkfee}
                                startdate={ele.startdate}
                              />
                            </Grid>
                          </Grid>
                          <DeleteAdvertiser
                            sendAdvertiserData={sendAdvertiserData}
                            advertiserId={ele.advertiserId}
                            advertiserName={ele.advertiserName}
                          />
                        </Card.Body>
                      </Card>
                    </Grid>
                  )}
                </Draggable>
              ))}

              {provided.placeholder}
            </Grid.Container>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

function PublisherComission(props) {
  const [visible, setVisible] = React.useState(false);
  const history = useHistory();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [publisherId, setPublisherId] = useState(null);
  const [warningmsg, setWarningMsg] = useState();
  const [amount, setAmount] = useState(null);

  const handler = () => setVisible(true);

  const calculateCommission = (publisherId, startDate, endDate) => {
    if (publisherId === null || !publisherId) {
      setAmount(null);
      setWarningMsg("Please Enter Publisher ID.");
    } else if (startDate === "" || endDate === "") {
      setAmount(null);
      setWarningMsg("Please provide Start date and End date both");
    } else if (
      publisherId !== null &&
      new Date(startDate) <= new Date(endDate)
    ) {
      setWarningMsg("");
      Adv_Pub_DataService.countpublishercommission(
        publisherId,
        startDate,
        endDate
      )
        .then((response) => {
          if (response.status === 200) {
            setWarningMsg(response.data.warningmessage);
            setAmount(response.data.amount);
          } else {
            localStorage.removeItem("TOU");
            return history.push("/Unauthorised");
          }
        })
        .catch((err) => {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        });
    } else {
      setAmount(null);
      setWarningMsg("End date can not be before Start date.");
    }
  };

  return (
    <div>
      <Button auto color="secondary" shadow onClick={handler}>
        Publisher Commision
      </Button>
      <Modal
        closeButton
        blur
        aria-labelledby="modal-title"
        open={visible}
        onClose={(e) => setVisible(false)}
      >
        <Modal.Header>
          <Text b size={18}>
            Publisher Commission
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Grid.Container gap={2}>
            <Grid>
              <Input
                status="secondary"
                bordered
                label="Publisher*"
                width="20rem"
                placeholder="ID"
                type="Number"
                value={publisherId}
                onChange={(e) => setPublisherId(e.target.value)}
              />
            </Grid>

            <Grid xl={7.5} xs={10} sm={7.5}>
              <Input
                status="secondary"
                width="100px"
                label="Start Date"
                value={startDate}
                type="date"
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Grid>
            <Spacer x={-2.5}></Spacer>
            <Grid xl={2} xs={10} sm={5}>
              <Input
                status="secondary"
                width="100px"
                label="End Date"
                value={endDate}
                type="date"
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Grid>
            <Grid>
              {amount != null ? (
                <Input
                  status="success"
                  readOnly
                  width="20rem"
                  value={
                    "Amount--  " +
                    new Intl.NumberFormat("de-DE", {
                      style: "currency",
                      currency: "EUR",
                    }).format(amount)
                  }
                />
              ) : (
                <></>
              )}
            </Grid>
          </Grid.Container>
          {warningmsg ? <Text color="error">{warningmsg}</Text> : <></>}
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat color="error" onClick={(e) => setVisible(false)}>
            Close
          </Button>
          <Button
            auto
            onClick={(e) =>
              calculateCommission(publisherId, startDate, endDate)
            }
          >
            Calculate
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

function DeleteAdvertiser(props) {
  const history = useHistory();

  const [visible, setVisible] = useState(false);
  const advertiserId = props.advertiserId;
  const advertiserName = props.advertiserName;
  const handler = () => setVisible(true);

  const closeHandler = () => {
    setVisible(false);
  };

  const handledeleteadvertiser = async (advertiserId) => {
    await Adv_Pub_DataService.deleteadvertiser(advertiserId)
      .then((response) => {
        if (response.status !== 200) {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        }
      })
      .catch((err) => {
        localStorage.removeItem("TOU");
        return history.push("/Unauthorised");
      });
    props.sendAdvertiserData({ isedited: true });
  };

  return (
    <>
      <Button auto flat color="error" onClick={handler}>
        Delete
      </Button>
      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
      >
        <Text id="modal-title" size={18}>
          It will delete all data
        </Text>
        <Text id="modal-title" size={18}>
          Do you really want to delete {advertiserName}..!!
        </Text>
        <Modal.Footer>
          <Button auto flat color="error" onClick={closeHandler}>
            Close
          </Button>
          <Button
            auto
            shadow
            color="error"
            onClick={(e) => {
              handledeleteadvertiser(advertiserId);
              closeHandler();
            }}
          >
            Delete Permanently
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function Editbutton(props) {
  // MODAL
  const [visible, setVisible] = React.useState(false);
  const handler = () => setVisible(true);

  const closeHandler = () => {
    setVisible(false);
  };

  const history = useHistory();

  const advertiserId = props.advertiserId;
  const advertiserName = props.advertiserName;
  const [daysback, setDaysback] = useState(props.daysback);
  const [fix, setFix] = useState(props.fix);
  const [min, setMin] = useState(props.min);
  const [percentage, setPercentage] = useState(props.percentage);
  const [base, setBase] = useState(props.base);
  const [x_range, setX_Range] = useState(props.x_range);
  const [y_range, setY_Range] = useState(props.y_range);
  const [netwerkfee, setNetwerkfee] = useState(props.netwerkfee);
  const [startdate, setStartdate] = useState(props.startdate);
  const [selectedLogo, setSelectedILogo] = useState("");

  const [saleinfoMessage, setSaleInfoMessage] = useState("");
  const [orderRef, setOrderRef] = useState("");

  const handlesave = async (e) => {
    let value = {
      advertiserId: advertiserId,
      daysback: daysback,
      fix: fix,
      min: min,
      percentage: percentage,
      base: base.anchorKey,
      x_range: x_range,
      y_range: y_range,
      netwerkfee: netwerkfee,
      startdate: startdate,
    };
    await Adv_Pub_DataService.editAdvertiserdetail(value)
      .then((response) => {
        if (response.status !== 200) {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        }
      })
      .catch((err) => {
        localStorage.removeItem("TOU");
        return history.push("/Unauthorised");
      });

    props.sendAdvertiserData({ isedited: true });
  };

  const uploadlogo = async (e) => {
    var logodetail = new FormData();
    logodetail.append("logo", selectedLogo);
    logodetail.append("advertiserId", advertiserId);
    await Adv_Pub_DataService.uploadLogo(logodetail)
      .then((response) => {
        if (response.status === 403) {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        }
      })
      .catch((err) => {
        localStorage.removeItem("TOU");
        return history.push("/Unauthorised");
      });
    setSelectedILogo("");
  };

  const handledeletesale = (advertiserId, orderRef) => {
    Adv_Pub_DataService.deleteSale(advertiserId, orderRef)
      .then((response) => {
        if (response.status === 200) {
          setSaleInfoMessage(response.data.saleinfoMessage);
          setOrderRef("");
        } else {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        }
      })
      .catch((err) => {
        localStorage.removeItem("TOU");
        return history.push("/Unauthorised");
      });
  };

  return (
    <div>
      <Button bordered color="gradient" auto onClick={handler}>
        Edit
      </Button>
      <Modal
        closeButton
        blur
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
      >
        <Modal.Header>
          <Text b id="modal-title" size={18}>
            Welcome to :-
            <Text b size={18}>
              {advertiserName}
            </Text>
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Dropdown>
            <Dropdown.Button flat color="secondary" css={{ tt: "capitalize" }}>
              {base}
            </Dropdown.Button>
            <Dropdown.Menu
              aria-label="Multiple selection actions"
              color="secondary"
              disallowEmptySelection
              selectionMode="single"
              selectedKeys={base}
              onSelectionChange={setBase}
            >
              <Dropdown.Item key="Turnover">Turnover</Dropdown.Item>
              <Dropdown.Item key="Publisher Fee">Publisher Fee</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Grid xs={20}>
            <Grid xs={6}>
              <Input
                rounded
                bordered
                label="Days back"
                placeholder={daysback}
                color="primary"
                onChange={(e) => setDaysback(e.target.value)}
              />
            </Grid>
            <Grid xs={1}></Grid>
            <Grid xs={6}>
              <Input
                rounded
                bordered
                label="Fix"
                placeholder={fix}
                color="primary"
                onChange={(e) => setFix(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid xs={20}>
            <Grid xs={6}>
              <Input
                rounded
                bordered
                label="Min"
                placeholder={min}
                color="primary"
                onChange={(e) => setMin(e.target.value)}
              />
            </Grid>
            <Grid xs={1}></Grid>
            <Grid xs={6}>
              <Input
                rounded
                bordered
                label="percentage"
                placeholder={percentage}
                color="primary"
                onChange={(e) => setPercentage(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid xs={20}>
            <Grid xs={6}>
              <Input
                rounded
                bordered
                label="X-Range"
                placeholder={x_range}
                color="primary"
                onChange={(e) => setX_Range(e.target.value)}
              />
            </Grid>
            <Grid xs={1}></Grid>
            <Grid xs={6}>
              <Input
                rounded
                bordered
                label="Y-Range"
                placeholder={y_range}
                color="primary"
                onChange={(e) => setY_Range(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid xs={20}>
            <Grid xs={6}>
              <Input
                rounded
                bordered
                label="Netwerk Fee"
                placeholder={netwerkfee}
                color="primary"
                onChange={(e) => setNetwerkfee(e.target.value)}
              />
            </Grid>
            <Grid xs={1}></Grid>
            <Grid xs={6}>
              <Input
                width="100px"
                label="Start Date"
                type="date"
                value={startdate}
                onChange={(e) => setStartdate(e.target.value)}
              />
            </Grid>
          </Grid>
          <Spacer y={1} />
          <Grid xs>
            <Input
              bordered
              labelPlaceholder="Sale OrderRef"
              onChange={(e) => setOrderRef(e.target.value)}
              required="true"
            />
            <Spacer x={2.5} />
            <Button
              bordered
              color="gradient"
              auto
              onClick={(e) => handledeletesale(advertiserId, orderRef)}
            >
              Delete sale
            </Button>
          </Grid>

          <Text color="secondary">{saleinfoMessage}</Text>
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat color="error" onClick={closeHandler}>
            Close
          </Button>
          <Button
            auto
            onClick={(e) => {
              handlesave();
              closeHandler();
            }}
          >
            Save
          </Button>
        </Modal.Footer>
        <hr />
        <div className="row " style={{ margin: "0.8rem" }}>
          <div className="col-sm-6 col-md-8">
            <input
              className="form-control"
              name="logo"
              type="file"
              id="formFile"
              accept=".jpg,.gif,.png"
              onChange={(e) => setSelectedILogo(e.target.files[0])}
            />
          </div>
          <div className="col-6 col-md-4">
            <button
              type="button"
              className="btn btn-dark"
              data-dismiss="modal"
              onClick={(e) => {
                uploadlogo();
                closeHandler();
              }}
            >
              Upload
            </button>
          </div>
        </div>
      </Modal>
    </div>
    // <div>
    //   <div
    //     className="btn-group"
    //     role="group"
    //     aria-label="Basic checkbox toggle button group"
    //     data-toggle="modal"
    //     data-target={"#edit" + props.advertiserId}
    //   >
    //     <label className="btn btn-outline-primary" htmlFor="btncheck1">
    //       Edit
    //     </label>
    //   </div>

    //   <div
    //     className="modal fade"
    //     id={"edit" + props.advertiserId}
    //     tabindex="-1"
    //     role="dialog"
    //     aria-labelledby="exampleModalLabel"
    //     aria-hidden="true"
    //   >
    //     <div className="modal-dialog" role="document">
    //       <div className="modal-content">
    //         <div className="modal-header">
    //           <h5 className="modal-title" id="exampleModalLabel">
    //             {advertiserName}
    //           </h5>
    //           <button
    //             type="button"
    //             className="close"
    //             data-dismiss="modal"
    //             aria-label="Close"
    //           >
    //             <span aria-hidden="true">&times;</span>
    //           </button>
    //         </div>
    //         <div className="modal-body">
    //           <p>Days back: </p>
    //           <input
    //             className="form-control form-control-sm"
    //             type="number"
    //             value={daysback}
    //             onChange={(e) => setDaysback(e.target.value)}
    //           />
    //           <p>Fix: </p>
    //           <input
    //             className="form-control form-control-sm"
    //             type="number"
    //             value={fix}
    //             onChange={(e) => setFix(e.target.value)}
    //           />
    //           <p>Min: </p>
    //           <input
    //             className="form-control form-control-sm"
    //             type="number"
    //             value={min}
    //             onChange={(e) => setMin(e.target.value)}
    //           />
    //           <p>%: </p>
    //           <input
    //             className="form-control form-control-sm"
    //             type="number"
    //             value={percentage}
    //             onChange={(e) => setPercentage(e.target.value)}
    //           />
    //           <p>Base: </p>
    //           <select
    //             className="form-control form-control-sm"
    //             onChange={(e) => setBase(e.target.value)}
    //             value={base}
    //             required
    //           >
    //             <option value="Turnover">Turnover</option>
    //             <option value="Publisher Fee">Publisher Fee</option>
    //           </select>
    //           <p>Netwerk Fees: </p>
    //           <input
    //             className="form-control form-control-sm"
    //             type="number"
    //             value={netwerkfee}
    //             onChange={(e) => setNetwerkfee(e.target.value)}
    //           />
    //           <p>Upload Logo</p>
    //           <div className="row ">
    //             <div className="col-sm-6 col-md-8"><input className="form-control" name ="logo" type="file" id="formFile" accept=".jpg,.gif,.png" onChange={(e) => setSelectedILogo(e.target.files[0])} /></div>
    //             <div className="col-6 col-md-4"><button
    //             type="button"
    //             className="btn btn-dark"
    //             data-dismiss="modal"

    //             onClick={(e) => {
    //               uploadlogo()
    //             }}
    //           >
    //             Upload
    //           </button></div>
    //           </div>
    //         </div>
    //         <div className="modal-footer">
    //           <button
    //             type="button"
    //             className="btn btn-secondary"
    //             data-dismiss="modal"
    //           >
    //             Close
    //           </button>
    //           <button
    //             type="button"
    //             className="btn btn-primary"
    //             data-dismiss="modal"
    //             onClick={(e) => {
    //               handlesave();
    //             }}
    //           >
    //             Save changes
    //           </button>
    //           < hr />

    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default Control;