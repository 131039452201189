import React, { useEffect, useState }  from 'react';
import { useHistory } from "react-router-dom";

import Navbar from "./Navigationbar.js";
import AdminDataservice from "../Services/adminService";

function Users(props) {
    const history = useHistory();

    const [userList, setUserList] = useState([])
    const [allocatedList, setAllocatedList] = useState([])
    const [notallocatedList, setNotAllocatedList] = useState([])

    useEffect(
      (e) => {
        AdminDataservice.getuserlist()
          .then((response) => {
            if (response.status === 200) {
              setUserList(response.data.data);
            } else {
              localStorage.removeItem("TOU");
              return history.push("/Unauthorised");
            }
          })
          .catch((err) => {
            if (err.response.status === 403) {
              localStorage.removeItem("TOU");
              return history.push("/Unauthorised");
            }
          });
      },
      [history]
    );

    const getadvertiserforuser = (username, action, advertiserId) => {
      AdminDataservice.getadvertiserforuser(
        username,
        action,
        advertiserId
      ).then((response) => {
        if (response.status === 200) {
          setAllocatedList(response.data.data.allocated);
          setNotAllocatedList(response.data.data.notallocated);
        }
      });
        
    };

    const handleuserdata = (username, isactive, superpower, role) => {
      AdminDataservice.edituserdata(username, isactive, superpower, role)
        .then((response) => {
          if (response.status === 200) {
            setUserList(response.data.data);
          } else {
            localStorage.removeItem("TOU");
            return history.push("/Unauthorised");
          }
        })
        .catch((err) => {
          if (err.response.status === 403) {
            localStorage.removeItem("TOU");
            return history.push("/Unauthorised");
          }
        });
    };

    return (
      <>
        <Navbar />
        
        <table className="table" style={{ marginBottom: "5rem" }}>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">User_Name</th>
              <th scope="col">Super Power</th>
              <th scope="col">Active/Deactive</th>
              <th scope="col">Role</th>
            </tr>
          </thead>
          <tbody>
            {userList.map((user, index) => {
              return (
                <>
                  <tr key={index} style={{ cursor: "pointer" }}>
                    <th scope="row">{index + 1}</th>
                    <td
                      data-toggle="modal"
                      data-target={"#user-" + index}
                      onClick={(e) => getadvertiserforuser(user._id)}
                    >
                      {user.username}
                    </td>
                    <td>
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id={user.username + "superpower"}
                          defaultChecked={user.superpower}
                          onChange={(e) =>
                            handleuserdata(
                              user._id,
                              null,
                              e.target.checked,
                              null
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id={user.username + "isactive"}
                          defaultChecked={user.isActive}
                          onChange={(e) =>
                            handleuserdata(
                              user._id,
                              e.target.checked,
                              null,
                              null
                            )
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="dropdown">
                        <button
                          className="btn  dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {user.accessCode === 222
                            ? "Account Manager"
                            : user.accessCode === 333
                            ? "Supporter"
                            : ""}
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                          style={{ padding: "0.5rem " }}
                        >
                          <li
                            onClick={(e) => {
                              handleuserdata(
                                user._id,
                                null,
                                null,
                                "Account Manager"
                              );
                            }}
                          >
                            Account Manager
                          </li>
                          <li
                            onClick={(e) => {
                              handleuserdata(user._id, null, null, "Supporter");
                            }}
                          >
                            Supporter
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <div
                    className="modal fade"
                    id={"user-" + index}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            className="modal-title"
                            id="exampleModalLongTitle"
                          >
                            {user.username}
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <h4>Allocated Advertiser</h4>
                          {allocatedList.map((allocatedadvertiser, index) => {
                            return (
                              <>
                                <li
                                  key={index}
                                  className="list-group-item list-group-item-success"
                                >
                                  {allocatedadvertiser.advertiserName}{" "}
                                  <span
                                    style={{
                                      float: "right",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) =>
                                      getadvertiserforuser(
                                        user._id,
                                        "remove",
                                        allocatedadvertiser.advertiserId
                                      )
                                    }
                                  >
                                    Remove
                                  </span>
                                </li>
                              </>
                            );
                          })}
                          <hr />
                          <h4>Not Allocated Advertiser</h4>
                          {notallocatedList.map(
                            (notallocatedadvertiser, index) => {
                              return (
                                <>
                                  <li
                                    key={index}
                                    className="list-group-item list-group-item-danger"
                                  >
                                    {notallocatedadvertiser.advertiserName}{" "}
                                    <span
                                      style={{
                                        float: "right",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        getadvertiserforuser(
                                          user._id,
                                          "add",
                                          notallocatedadvertiser.advertiserId
                                        )
                                      }
                                    >
                                      Add
                                    </span>
                                  </li>
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </tbody>
        </table>
      </>
    );
}

export default Users;