import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Card, Row,  Col, Grid , Input, Text , Button, Modal, Spacer} from "@nextui-org/react";

import logo_img from "../images/OU_Transparent_Logo.png";
import loginpage_img from "../images/login_page_photo_2.svg";
import AdminDataservice from "../Services/adminService";

function Loginpage(props) {
  const [visible, setVisible] = React.useState(false);
  const handler = () => setVisible(true);
  const closeHandler = () => {
    setVisible(false);
  };
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errormessage, setErrorMessage] = useState("");
  const history = useHistory();

  //   useEffect((e) => {
  //     if(localStorage.getItem("TOU")){
  //       return history.push("/")
  //     }
  //     else{
  //       return history.push("/login")
  //     }

  // },[history]);

  const handleSubmit = (e) => {
    if (username === "" || password === "") {
      setErrorMessage("Please Enter all Required Data.");
    } else {
      const value = { username: username, password: password };
      AdminDataservice.login(value)
        .then((response) => {
          if (response.status === 200) {
            setErrorMessage("");
            localStorage.setItem("TOU", response.data.token);
            history.replace("/");
          } else {
            setErrorMessage("Invalid Username or Password !!");
          }
        })
        .catch((error) => {
          setErrorMessage("Invalid Username or Password !!");
          //alert("Got Error");
        });
    }
  };

  return (
    <div>
      {/* <nav id="navbar-example2" className="navbar navbar-light bg-light">
        <img src={logo_img} alt="logo img" style={{ width: "10rem" }} />
      </nav> */}
      <div style={{ padding: "3rem", width: "100%" }}>
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Grid xs>
                    <Grid xs>
                      <img
                        src={loginpage_img}
                        alt="login bg img"
                        style={{ width: "65rem" }}
                      />
                    </Grid>
                    <Grid xl>
                      <Container>
                        <>
                          <Grid xs>
                            <img
                              src={logo_img}
                              alt="login bg img"
                              style={{ width: "30rem" }}
                            />
                          </Grid>
                          <Spacer y={3} />
                          <Text
                            h1
                            size={40}
                            css={{
                              textGradient:
                                "45deg, $blue600 -20%, $pink600 50%",
                            }}
                            weight="bold"
                          >
                            Welcome to
                          </Text>
                          <Text
                            h1
                            size={60}
                            css={{
                              textGradient:
                                "45deg, $green800 -20%, $blue600 100%",
                            }}
                            weight="bold"
                          >
                            ONLINE UNITED GmbH
                          </Text>
                          <Text
                            h1
                            size={45}
                            css={{
                              textGradient:
                                "45deg, $purple600 -20%, $yellow600 100%",
                            }}
                            weight="bold"
                          >
                            We increase your performance.
                          </Text>
                          <Text
                            h1
                            size={45}
                            css={{
                              textGradient:
                                "45deg, $yellow600 -20%, $red600 100%",
                            }}
                            weight="bold"
                          >
                            Enjoy your journey.
                          </Text>
                          <Spacer y={1.6} />
                          <Grid>
                            <Button
                              onClick={handler}
                              size="lg"
                              auto
                              color="primary"
                              rounded
                              bordered
                            >
                              Sign in
                            </Button>
                            <Modal
                              closeButton
                              preventClose
                              aria-labelledby="modal-title"
                              open={visible}
                              onClose={closeHandler}
                            >
                              <Modal.Header>
                                <Grid xs>
                                  <img
                                    src={logo_img}
                                    alt="login bg img"
                                    style={{ width: "20rem" }}
                                  />
                                </Grid>
                              </Modal.Header>
                              <Modal.Body>
                                <Input
                                  bordered
                                  fullWidth
                                  autoFocus
                                  color="primary"
                                  size="lg"
                                  label="Email *"
                                  placeholder="Email"
                                  onChange={(e) => setUsername(e.target.value)}
                                />
                                <Input.Password
                                  bordered
                                  fullWidth
                                  color="primary"
                                  size="lg"
                                  label="Password *"
                                  placeholder="Password"
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                                {errormessage ? (
                                  <Text color="error">{errormessage}</Text>
                                ) : (
                                  <></>
                                )}
                              </Modal.Body>
                              <Spacer y={1} />
                              <Modal.Footer>
                                <Button
                                  auto
                                  flat
                                  color="error"
                                  onClick={closeHandler}
                                >
                                  Close
                                </Button>
                                <Button auto onClick={handleSubmit}>
                                  Sign in
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </Grid>
                        </>
                      </Container>
                    </Grid>
                  </Grid>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div className="limiter">
        <div className="container-login100 ">
          <div className="wrap-login100 card border-dark">
            <div className="login100-form-title">
              <span className="login100-form-title-1">Sign In</span>
            </div>

            <form
              className="login100-form validate-form"
              onSubmit={handleSubmit}
            >
              <div
                className="wrap-input100 validate-input m-b-26"
                data-validate="Username is required"
              >
                <span className="label-input100">Username</span>
                <input
                  className="input100"
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
                <span className="focus-input100"></span>
              </div>

              <div
                className="wrap-input100 validate-input m-b-18"
                data-validate="Password is required"
              >
                <span className="label-input100">Password</span>
                <input
                  className="input100"
                  type="password"
                  name="password"
                  placeholder="Enter password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <span className="focus-input100"></span>
              </div>
              {errormessage ? <h6 style={{color: "red", marginTop: "2rem"}}>{errormessage}</h6> : <div></div>}
              <div
                className="container-login100-form-btn"
                style={{ marginTop: "5%" }}
              >
                <button type="submit" className="login100-form-btn">
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Loginpage;
