import http from "./Http-Common";

class Notes_Service {
  getallnotes(advertiserId) {
    return http.post("/notes/getallnotes", {
      advertiserId,
    });
  }

  insertNotes(notedetail, advertiserId) {
    return http.post("/notes/insertnotes", {
      notedetail,
      advertiserId,
    });
  }

  updateNotes(noteId, notedetail) {
    return http.post("/notes/updatenote", {
      noteId,
      notedetail,
    });
  }
}

export default new Notes_Service();
