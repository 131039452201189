import React  from 'react';
import { Link, useHistory } from "react-router-dom";
import { AiOutlineLogout } from "react-icons/ai";
import jwt from "jwt-decode";

import "../Styles/loginmain.css";
import logo_img from "../images/OU_Transparent_Logo.png";

// let hours = 2

const Navigationbar = (props) => {
  const history = useHistory();
  const accessCode = jwt(localStorage.getItem("TOU")).accessCode;
  const handleLogout = () => {
    localStorage.removeItem("TOU");
    return history.replace("/login");
    // return window.location.href = "/login"
  };

  return (
    <div>
      <div>
        <nav id="navbar-example2 " className="navbar bg-light ">
          <Link to="/">
            <img src={logo_img} alt="logo img" style={{ width: "10rem" }} />
          </Link>

          <ul className="nav ">
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/publishercomparison"
                style={{ color: "black", fontSize: "18px" }}
                target="_blank"
              >
                Publisher_Comparison
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/campaign"
                style={{ color: "black", fontSize: "18px" }}
              >
                Campaign
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/upload"
                style={{ color: "black", fontSize: "18px" }}
              >
                Upload
              </Link>
            </li>

            {accessCode === 111 ? (
              <>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/users"
                    style={{ color: "black", fontSize: "18px" }}
                  >
                    Users
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/control"
                    style={{ color: "black", fontSize: "18px" }}
                  >
                    Control
                  </Link>
                </li>
              </>
            ) : (
              <></>
            )}

            <li
              className="nav-item"
              data-toggle="modal"
              data-target="#logoutmodal"
            >
              <div
                className="nav-link"
                style={{ color: "red", fontSize: "18px" }}
              >
                <AiOutlineLogout />

                <div
                  className="modal fade"
                  id="logoutmodal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-body">See you tomorrow !!</div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={handleLogout}
                          data-dismiss="modal"
                        >
                          Logout
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navigationbar;
