import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Button, Text, Grid, Textarea } from "@nextui-org/react";
import { BsFillPatchPlusFill } from "react-icons/bs";

import NotesService from "../Services/NotesService";

export default function PersonalNotes(props) {
  const history = useHistory();
  const advertiserId = props.advertiserId;
  const advertiserName = props.advertiserName;
  //   let noteId = props.noteId ? props.noteId : "";
  const [noteErr, setNoteErr] = useState("");
  const [noteDetail, setNoteDetail] = useState("");

  const [visible, setVisible] = React.useState(false);
  const handler = () => setVisible(true);

  const closeHandler = () => {
    setVisible(false);
  };

  const makeNotes = () => {
    if (noteDetail === "") {
      setNoteErr("Note cannot be Empty.");
    } else {
      NotesService.insertNotes(noteDetail, advertiserId)
        .then((response) => {
          if (response.status === 200) {
            setNoteErr("");
            props.getDatafromPersonalNotes(response.data.personalNote);
            setNoteDetail("");
            closeHandler();
          } else {
            localStorage.removeItem("TOU");
            return history.push("/Unauthorised");
          }
        })
        .catch((err) => {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        });
    }
  };

  return (
    <div>
      <button onClick={handler}>
        <BsFillPatchPlusFill color="yellow" size={"2rem"} />
      </button>

      <Modal
        closeButton
        animated={true}
        width={"60rem"}
        blur
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
      >
        <Modal.Header>
          <Text h4>{advertiserName}</Text>
        </Modal.Header>
        <Modal.Body>
          <Grid css={{ paddingTop: "1rem" }}>
            <Textarea
              bordered
              labelPlaceholder="Personal Note"
              status="default"
              fullWidth
              value={noteDetail}
              rows={10}
              onChange={(e) => setNoteDetail(e.target.value)}
            />
          </Grid>
          {noteErr ? <Text color="error">{noteErr}</Text> : ""}
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat color="error" onPress={closeHandler}>
            Close
          </Button>
          <Button auto onPress={makeNotes}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
