import http from "./Http-Common";

class Task_Service {
  addnewtask(
    description,
    type,
    advertisers,
    to_acc_manager,
    to_supporter,
    onHomepage
  ) {
    return http.post("/task/inserttask", {
      description,
      advertisers,
      type,
      to_acc_manager,
      to_supporter,
      onHomepage,
    });
  }

  getalltasks(advertiserId) {
    return http.post("/task/getalltask", {
      advertiserId,
    });
  }

  update_task_tasknumber(taskId, tasknumber) {
    return http.post("/task/update_tasknumber", {
      taskId,
      tasknumber,
    });
  }

  update_task_done(isdone, taskId, advertiserId, tasktype) {
    return http.post("/task/update_task_done", {
      isdone,
      taskId,
      advertiserId,
      tasktype,
    });
  }

  addNewPersonalTask(
    advertiserId,
    description,
    tasktype,
    selectedDate,
    onWeek,
    selectedDaysList,
    selectedUser
  ) {
    return http.post("/task/insertpersonaltask", {
      advertiserId,
      description,
      tasktype,
      selectedDate,
      onWeek,
      selectedDaysList,
      selectedUser,
    });
  }

  deletetask(taskId, tasktype) {
    return http.post("/task/delete_task", {
      taskId,
      tasktype,
    });
  }
}

export default new Task_Service();
