import React from 'react';
import { BrowserRouter as Router, Switch  } from "react-router-dom";
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import { NextUIProvider } from '@nextui-org/react';

import Homepage from './Components/Homepage.js';
import Adv_Pub from "./Components/Adv_Pub.js";
import Advertiser from "./Components/Advertiser.js";
import Publisher from "./Components/Publisher.js";
import Publishercomparison from "./Components/Publishercomparison.js";
import Compaign from "./Components/Campaign.js";
import CampaignReport from "./Components/Campaignreport.js";
import Brevoupload from "./Components/Brevoupload.js";
import Control from "./Components/Control.js";
import Data from "./Components/Data.js";
import Users from "./Components/Users.js";
import Loginpage from "./Components/Loginpage.js";
import Unauthorized from "./Components/Unauthorized.js";

import "./App.css";
import jwt from "jwt-decode";

const requireLogin = (to, from, next) => {
  let accessCode = 0;
  if (localStorage.getItem("TOU")) {
    accessCode = jwt(localStorage.getItem("TOU")).accessCode;
  }
  if (to.meta.auth) {
    if (
      !to.meta.userauth &&
      (accessCode === 111 || accessCode === 222 || accessCode === 333)
    ) {
      next();
    } else if (to.meta.userauth && accessCode === 111) {
      next();
    } else {
      next.redirect("/login");
    }
  } else if (
    to.meta.loginauth &&
    (accessCode === 111 || accessCode === 222 || accessCode === 333)
  ) {
    next.redirect("/");
  } else if (to.meta.datapageauth) {
    next();
  } else {
    next.redirect("/login");
  }
};

function App({ Component, pageProps }) {
  return (
    <NextUIProvider>
      <Router>
        <GuardProvider guards={[requireLogin]}>
          <Switch>
            <GuardedRoute
              path="/login"
              exact
              component={Loginpage}
              meta={{ loginauth: true }}
            />
            <GuardedRoute
              path="/"
              exact
              component={Homepage}
              meta={{ auth: true, userauth: false }}
            />
            <GuardedRoute
              path="/advertiser"
              component={Advertiser}
              meta={{ auth: true, userauth: false }}
            />
            <GuardedRoute
              path="/publisher"
              component={Publisher}
              meta={{ auth: true, userauth: false }}
            />
            <GuardedRoute
              path="/adv_pub"
              component={Adv_Pub}
              meta={{ auth: true, userauth: false }}
            />
            <GuardedRoute
              path="/publishercomparison"
              component={Publishercomparison}
              meta={{ auth: true, userauth: false }}
            />
            <GuardedRoute
              path="/campaign"
              component={Compaign}
              meta={{ auth: true, userauth: false }}
            />
            <GuardedRoute
              path="/campaignreport"
              component={CampaignReport}
              meta={{ auth: true, userauth: false }}
            />
            <GuardedRoute
              path="/upload"
              component={Brevoupload}
              meta={{ auth: true, userauth: false }}
            />
            <GuardedRoute
              path="/control"
              exact
              component={Control}
              meta={{ auth: true, userauth: true }}
            />
            <GuardedRoute
              path="/users"
              exact
              component={Users}
              meta={{ auth: true, userauth: true }}
            />
            <GuardedRoute
              path="/advertiserservice"
              component={Data}
              meta={{ datapageauth: true }}
            />
            <GuardedRoute path="*" component={Unauthorized} />
          </Switch>
        </GuardProvider>
      </Router>
    </NextUIProvider>
  );
}

export default App;
