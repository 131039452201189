import React from "react";

function Publishercomparison(props) {
  const publisher_comparison_report =
    "https://app.powerbi.com/view?r=eyJrIjoiN2E3Mjk5NTctNWZlNS00ZDBhLWFlZTMtNjM2ZWU2NjViNDE1IiwidCI6ImQ5ZTE0YzFkLTA2MzAtNGExYS04ZWU5LTExZDVlYTQ1NzA3ZCJ9";
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <iframe
        src={publisher_comparison_report}
        style={{ width: "100%", height: "100%", border: "none" }}
        allowFullScreen="true"
        title="Publisher_Comparison_Report"
      ></iframe>
    </div>
  );
}

export default Publishercomparison;
