import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Modal,
  Button,
  Text,
  Grid,
  Dropdown,
  Textarea,
} from "@nextui-org/react";

import publisherService from "../Services/publisherService";

export default function PublisherNotes(props) {
  const history = useHistory();

  const advertiserArray = props.advertiserArray;
  const publishermothername = props.publishermothername;
  const publishernoteId = props?.publishernoteId ? props.publishernoteId : null;
  const notedetail = props?.notedetail ? props.notedetail : null;
  const connectedAdvertiser = props?.connectedAdvertiser
    ? props.connectedAdvertiser
    : null;

  const [visible, setVisible] = useState(false);
  const [notes, setNotes] = useState("");
  const [taskErr, setTaskErr] = useState("");
  const [taskmessage, setTaskmessage] = useState("");
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(
    connectedAdvertiser !== null ? connectedAdvertiser : []
  );

  useEffect(() => {
    setNotes(notedetail || "");
  }, [props, notedetail]);

  const tempAdvertiserArray = ["all"];

  advertiserArray.forEach((a) => {
    tempAdvertiserArray.push(a._id);
  });

  const selectedAvertiserlist = React.useMemo(
    () => Array.from(selectedAdvertiser).concat(),
    [selectedAdvertiser]
  );

  const handleselectedadvertiser = async (value) => {
    let tempadvertiserlistarray = [];
    if (selectedAvertiserlist.includes(value)) {
      tempadvertiserlistarray = [];
    } else {
      tempadvertiserlistarray = tempAdvertiserArray;
    }
    setSelectedAdvertiser(tempadvertiserlistarray);
  };

  const handler = () => setVisible(true);
  const closeHandler = () => {
    setVisible(false);
  };

  const insertnote = () => {
    if (selectedAvertiserlist.includes("all")) {
      var index = selectedAvertiserlist.indexOf("all");
      if (index !== -1) {
        selectedAvertiserlist.splice(index, 1);
      }
    }

    if (notes === "") {
      setTaskErr("Note Description Required");
      setTaskmessage("");
    } else if (selectedAvertiserlist.length <= 0) {
      setTaskErr("Please select Advertiser from list");
      setTaskmessage("");
    } else {
      if (publishernoteId !== null) {
        publisherService
          .updatepublishernote(
            publishermothername,
            publishernoteId,
            notes,
            selectedAvertiserlist
          )
          .then((response) => {
            if (response.status !== 200) {
              localStorage.removeItem("TOU");
              return history.push("/Unauthorised");
            } else {
              setTaskErr("");
              setTaskmessage(response.data.message);
              props.getDataFromPublisherNotes(response.data.publishernote);
              setTaskmessage("");
              closeHandler();
            }
          });
      } else {
        publisherService
          .insertpublishernote(
            publishermothername,
            selectedAvertiserlist,
            notes
          )
          .then((response) => {
            if (response.status !== 200) {
              localStorage.removeItem("TOU");
              return history.push("/Unauthorised");
            } else {
              setNotes("");
              setSelectedAdvertiser(new Set(["all"]));
              setTaskErr("");
              setTaskmessage(response.data.message);
              props.getDataFromPublisherNotes(response.data.publishernote);
              setTaskmessage("");
              closeHandler();
            }
          });
      }
    }
  };

  return (
    <div>
      <Button
        auto
        color={publishernoteId !== null ? "error" : "primary"}
        onClick={handler}
      >
        {publishernoteId !== null ? "Edit" : "New Notes"}
      </Button>
      <Modal
        closeButton
        animated={true}
        color="blue"
        width={"35rem"}
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
      >
        <Modal.Header>
          <Text b size={18}>
            Notes for - {publishermothername}
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Dropdown css={{ width: "25rem" }}>
            <Dropdown.Button flat color="primary" css={{ tt: "capitalize" }}>
              Select Advertiser from list
            </Dropdown.Button>
            <Dropdown.Menu
              aria-label="Multiple selection actions"
              css={{
                maxWidth: "fit-content",
                zIndex: "$1000",
                overflow: "visible",
              }}
              color="primary"
              selectionMode="multiple"
              selectedKeys={selectedAdvertiser}
              onSelectionChange={setSelectedAdvertiser}
              onAction={(value) => {
                if (value === "all") {
                  handleselectedadvertiser(value);
                }
              }}
              variant="shadow"
            >
              <Dropdown.Item key="all">All</Dropdown.Item>
              {advertiserArray.map((ele, index) => {
                return (
                  <Dropdown.Item key={ele._id} css={{ maxWidth: "25rem" }}>
                    {ele.advertiserName}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          <Grid>
            <Textarea
              required="true"
              rounded
              bordered
              fullWidth
              label="Note Description"
              placeholder="Enter Description"
              color="primary"
              value={notes}
              minRows={20}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Grid>

          {taskErr ? <Text color="error">{taskErr}</Text> : ""}
          {taskmessage ? <Text color="success">{taskmessage}</Text> : ""}
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat color="error" onPress={closeHandler}>
            Close
          </Button>
          <Button auto onPress={insertnote}>
            {publishernoteId !== null ? "Save Note" : "Generate Note"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
