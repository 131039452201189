import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Modal,
  Button,
  Text,
  Input,
  Checkbox,
  Grid,
  Dropdown,
  Radio,
} from "@nextui-org/react";
import Task_Service from "../Services/taskService";

export default function Addtask(props) {
  const history = useHistory();

  const advertiserArray = props.advertiserArray;

  const [visible, setVisible] = useState(false);
  const [onHomepage, setOnHomepage] = useState(false);
  const [taskErr, setTaskErr] = useState("");
  const [taskmessage, setTaskmessage] = useState("");
  const [tasktype, setTaskType] = useState(new Set(["daily"]));
  const [selectedAdvertiser, setSelectedAdvertiser] = useState([]);

  const tempAdvertiserArray = ["all"];

  advertiserArray.forEach((a) => {
    tempAdvertiserArray.push(JSON.stringify(a.advertiserId));
  });

  const [to_acc_manager, setTo_Acc_Manager] = useState(false);
  const [to_supporter, setTo_Supporter] = useState(false);

  const [description, setDescription] = useState("");

  const selectedtasktype = React.useMemo(
    () => Array.from(tasktype).concat(),
    [tasktype]
  );

  const selectedAvertiserlist = React.useMemo(
    () => Array.from(selectedAdvertiser).concat(),
    [selectedAdvertiser]
  );

  const handleselectedadvertiser = async (value) => {
    let tempadvertiserlistarray = [];
    if (selectedAvertiserlist.includes(value)) {
      tempadvertiserlistarray = [];
    } else {
      tempadvertiserlistarray = tempAdvertiserArray;
    }
    setSelectedAdvertiser(tempadvertiserlistarray);
  };

  const handler = () => setVisible(true);
  const closeHandler = () => {
    setVisible(false);
  };

  const insertnewTask = () => {
    if (selectedAvertiserlist.includes("all")) {
      var index = selectedAvertiserlist.indexOf("all");
      if (index !== -1) {
        selectedAvertiserlist.splice(index, 1);
      }
    }

    if (description === "") {
      setTaskErr("Task Description Required");
      setTaskmessage("");
    } else if (to_acc_manager === false && to_supporter === false) {
      setTaskErr("You have to assign task to atleast one user.");
      setTaskmessage("");
    } else if (selectedAvertiserlist.length <= 0) {
      setTaskErr("Please select Advertiser from list");
      setTaskmessage("");
    } else {
      Task_Service.addnewtask(
        description,
        selectedtasktype,
        selectedAvertiserlist,
        to_acc_manager,
        to_supporter,
        onHomepage
      )
        .then((response) => {
          if (response.status !== 200) {
            localStorage.removeItem("TOU");
            return history.push("/Unauthorised");
          } else {
            setDescription("");
            setTaskType(new Set(["daily"]));
            setSelectedAdvertiser(new Set(["all"]));
            setTaskErr("");
            setTaskmessage(response.data.taskmessage);
            setTo_Acc_Manager(false);
            setTo_Supporter(false);
            setTaskmessage("");
            closeHandler();
          }
        })
        .catch((err) => {
          localStorage.removeItem("TOU");
          return history.push("/Unauthorised");
        });
    }
  };

  return (
    <div>
      <Button auto color="success" onClick={handler}>
        Add Task
      </Button>
      <Modal
        closeButton
        animated={true}
        width={"35rem"}
        blur
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
      >
        <Modal.Header>
          <Text b size={18}>
            New Task
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Grid>
            <Input
              required="true"
              rounded
              bordered
              fullWidth
              label="Task Description"
              placeholder="Enter Description"
              color="success"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
          <Radio.Group
            defaultValue="false"
            orientation="horizontal"
            onChange={(value) => {
              setOnHomepage(value);
            }}
          >
            <Radio value="true" color="success" labelColor="success">
              Show on Homepage
            </Radio>
            <Radio value="false" color="error" labelColor="error">
              Hide from Homepage
            </Radio>
          </Radio.Group>
          <Dropdown>
            <Dropdown.Button flat color="success" css={{ tt: "capitalize" }}>
              {selectedtasktype}
            </Dropdown.Button>
            <Dropdown.Menu
              color="success"
              disallowEmptySelection
              selectionMode="single"
              selectedKeys={tasktype}
              onSelectionChange={setTaskType}
            >
              <Dropdown.Item key="daily">Dailys</Dropdown.Item>
              <Dropdown.Item key="weekly">Weekly</Dropdown.Item>
              <Dropdown.Item key="monthly">Monthly</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown css={{ width: "25rem" }}>
            <Dropdown.Button flat color="success" css={{ tt: "capitalize" }}>
              Select Advertiser from list
            </Dropdown.Button>
            <Dropdown.Menu
              aria-label="Multiple selection actions"
              css={{ maxWidth: "fit-content" }}
              color="success"
              selectionMode="multiple"
              selectedKeys={selectedAdvertiser}
              onSelectionChange={setSelectedAdvertiser}
              onAction={(value) => {
                if (value === "all") {
                  handleselectedadvertiser(value);
                }
              }}
              variant="shadow"
            >
              <Dropdown.Item key="all">All</Dropdown.Item>
              {advertiserArray.map((ele, index) => {
                return (
                  <Dropdown.Item
                    key={ele.advertiserId}
                    css={{ maxWidth: "25rem" }}
                  >
                    {ele.advertiserName}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>

          <Checkbox
            isSelected={to_acc_manager}
            color="success"
            onChange={setTo_Acc_Manager}
          >
            Account Manager
          </Checkbox>
          <Checkbox
            isSelected={to_supporter}
            color="success"
            onChange={setTo_Supporter}
          >
            Supporter
          </Checkbox>

          {taskErr ? <Text color="error">{taskErr}</Text> : ""}
          {taskmessage ? <Text color="success">{taskmessage}</Text> : ""}
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat color="error" onPress={closeHandler}>
            Close
          </Button>
          <Button auto onPress={insertnewTask}>
            Generate Task
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
