import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import { BiAnalyse } from "react-icons/bi";
import { BsTrashFill } from "react-icons/bs";

import { Form, Row, Col, Table, Pagination } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./Navigationbar";
import campaignService from "../Services/campaignService";
import Adv_Pub_DataService from "../Services/advertiserService";
import publisherService from "../Services/publisherService";

import AddCampaign from "./AddCampaign";

const Campaign = () => {
  const history = useHistory();

  const [filterCriteria, setFilterCriteria] = useState({
    network: "",
    advertiser: [],
    publisher: "",
    type: "",
    year: "",
    status: "",
  });

  const [uniqueNetworks, setUniqueNetworks] = useState([]);
  const [uniqueAdvertisers, setUniqueAdvertisers] = useState([]);
  const [uniqueTypes, setUniqueTypes] = useState([]);
  const [uniqueYears, setUniqueYears] = useState([]);
  const [uniqueStatus, setUniqueStatus] = useState([]);
  const [uniquePublishers, setUniquePublishers] = useState([]);
  const [alladvertiserArray, setAllAdvertiserArray] = useState([]);
  const [allnetwork, setAllNetwork] = useState([]);
  const [allpublisher, setAllPublisher] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc"); // or "desc"

  useEffect(
    (e) => {
      Adv_Pub_DataService.getAllAdvertiser()
        .then((response) => {
          if (response.status === 200) {
            setAllAdvertiserArray(response.data.data);
          }
        })
        .catch((err) => {
          if (err.response.status === 403) {
            localStorage.removeItem("TOU");
            return history.push("/Unauthorised");
          }
        });
      publisherService.getallnetwork().then((response) => {
        if (response.status === 200) {
          setAllNetwork(response.data.allnetwork[0].allnetwork);
        }
      });

      publisherService.getallpublisher().then((response) => {
        if (response.status === 200) {
          setAllPublisher(response.data.allpublisher);
        }
      });

      fetchCampaigns(currentPage, filterCriteria, sortColumn, sortOrder);
    },
    [currentPage, filterCriteria, history, sortColumn, sortOrder]
  );

  const fetchCampaigns = async (page, filters, sortColumn, sortOrder) => {
    try {
      const response = await campaignService.getallCampaign(
        page,
        filters,
        sortColumn,
        sortOrder
      );
      setCampaigns(response.data.campaigns);
      setTotalPages(response.data.totalPages);
      setUniqueNetworks(response.data.uniqueNetworks);
      setUniqueAdvertisers(response.data.uniqueAdvertisers);
      setUniqueTypes(response.data.uniqueTypes);
      setUniqueYears(response.data.uniqueYears);
      setUniquePublishers(response.data.uniquePublishers);
      setUniqueStatus(response.data.uniqueStatus);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "advertiser") {
      const numValue = Number(value);

      setFilterCriteria((prevState) => {
        const advertisers = prevState.advertiser.includes(numValue)
          ? prevState.advertiser.filter((item) => item !== numValue) // Remove if it already exists
          : [...prevState.advertiser, numValue]; // Add if it doesn't exist
        return { ...prevState, advertiser: advertisers };
      });
    } else {
      setFilterCriteria({ ...filterCriteria, [name]: value });
    }
    setCurrentPage(1);
  };

  const handleSortChange = (column) => {
    const order = sortOrder === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortOrder(order);
    fetchCampaigns(1, column, order); // Reset to the first page when sorting changes
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getAdvertiserName = (advertiserId) => {
    const advertiser = alladvertiserArray.find(
      (ad) => Number(ad.advertiserId) === Number(advertiserId)
    );
    return advertiser ? advertiser.advertiserName : "Unknown Advertiser";
  };

  const updatcampaign = (campaign, campaignId) => {
    const index = campaigns.findIndex((c) => c._id === campaignId);
    let updatedCampaigns;

    if (index !== -1) {
      updatedCampaigns = [
        ...campaigns.slice(0, index),
        {
          ...campaigns[index], // Spread the existing campaign fields
          ...campaign, // Overwrite with the new fields from the campaign object
        },
        ...campaigns.slice(index + 1),
      ];
    } else {
      updatedCampaigns = [...campaigns, campaign];
    }
    setCampaigns(updatedCampaigns);
  };

  const handledeletecampaign = (campaignid) => {
    campaignService.deletecampaign(campaignid).then(() => {
      let updatedCampaigns = campaigns.filter((c) => c._id !== campaignid);
      setCampaigns(updatedCampaigns);
    });
  };

  return (
    <div style={{ backgroundColor: "lightgrey" }}>
      <Navbar />
      <AddCampaign
        alladvertiserArray={alladvertiserArray}
        campaign={null}
        allnetwork={allnetwork}
        allpublisher={allpublisher}
        updatcampaign={updatcampaign}
      />

      {/* <div className="container mt-9">
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="network">
            <Form.Label>Network*</Form.Label>
            <Form.Control
              as="select"
              name="network"
              value={formData.network}
              onChange={handleChange}
              required
            >
              <option value="">Select Network</option>
              {allnetwork.map((network, index) => (
                <option key={index} value={network} tabIndex={index}>
                  {network.toUpperCase()}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="advertiser">
            <Form.Label>Advertiser*</Form.Label>
            <Form.Control
              as="select"
              name="advertiser"
              value={formData.advertiser}
              onChange={handleChange}
              required
            >
              <option value="">Select Advertiser</option>
              {advertiserArray.map((adv, index) => (
                <option key={index} value={adv.advertiserId} tabIndex={index}>
                  {adv.advertiserName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="publishers">
            <Form.Label>Target Publishers</Form.Label>
            <Select
              isMulti
              name="publishers"
              options={publisherArray.map((publisher) => ({
                value: publisher.publisherId,
                label: publisher.publisherName,
              }))}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handlePublisherChange}
            />
            <h6 style={{ color: "red" }}>
              If you not select any publisher then it will consider all
              publishers for this Advertiser.
            </h6>
          </Form.Group>

          <Form.Group>
            <Row>
              <Col>
                <Form.Label>Start Date*</Form.Label>
                <Form.Control
                  type="date"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col>
                <Form.Label>End Date*</Form.Label>
                <Form.Control
                  type="date"
                  name="endDate"
                  value={formData.endDate}
                  onChange={handleChange}
                  required
                  min={formData.startDate}
                />
              </Col>
            </Row>
          </Form.Group>

          <Form.Group controlId="type">
            <Form.Label>Type*</Form.Label>
            <Form.Control
              as="select"
              name="type"
              value={formData.type}
              onChange={handleChange}
              required
            >
              <option value="">Select Type</option>
              <option value="Exclusive coupon code">
                Exclusive coupon code
              </option>
              <option value="General coupon code">General coupon code</option>
              <option value="CPAi">CPAi</option>
              <option value="Fixed fee">Fixed fee</option>
              <option value="Exclusive coupon & CPAi">
                Exclusive coupon & CPAi
              </option>
              <option value="Exclusive coupon & Fixed fee">
                Exclusive coupon & Fixed fee
              </option>
              <option value="CPAi & Fixed fee">CPAi & Fixed fee</option>
              <option value="Exclusive coupon & CPAi & Fixed fee">
                Exclusive coupon & CPAi & Fixed fee
              </option>
              <option value="Other">Other</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="title">
            <Form.Label>Title*</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="description">
            <Form.Label>Description*</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <br />
          <Button variant="success" type="submit">
            Submit
          </Button>
        </Form>
      </div> */}
      <hr />

      <div className="container mt-5">
        <Form>
          <Row className="mb-6">
            <span>Filters : </span>

            <Col xs={2}>
              <Form.Group controlId="filterNetwork">
                <Form.Control
                  as="select"
                  name="network"
                  value={filterCriteria.network}
                  onChange={handleFilterChange}
                >
                  <option value="">Select Network</option>
                  {uniqueNetworks.map((network, index) => (
                    <option key={index} value={network}>
                      {network.toUpperCase()}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={2}>
              <Form.Group controlId="filterAdvertiser">
                <Form.Control
                  as="select"
                  name="advertiser"
                  value={filterCriteria.advertiser}
                  onChange={handleFilterChange}
                >
                  <option value="">Select Advertiser</option>
                  {uniqueAdvertisers.map((advertiser, index) => (
                    <option
                      key={index}
                      value={advertiser}
                      style={{
                        backgroundColor: filterCriteria.advertiser.includes(
                          advertiser
                        )
                          ? "Green"
                          : "white",
                        color: filterCriteria.advertiser.includes(advertiser)
                          ? "white"
                          : "black",
                      }}
                    >
                      {getAdvertiserName(advertiser)}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={2}>
              <Form.Group controlId="filterPublisher">
                <Form.Control
                  as="select"
                  name="publisher"
                  value={filterCriteria.publisher}
                  onChange={handleFilterChange}
                >
                  <option value="">Select Publisher</option>
                  <option value="all">All Publishers</option>
                  {uniquePublishers.map((publisher, index) => (
                    <option key={index} value={publisher.value}>
                      {publisher.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={2}>
              <Form.Group controlId="filterType">
                <Form.Control
                  as="select"
                  name="type"
                  value={filterCriteria.type}
                  onChange={handleFilterChange}
                >
                  <option value="">Select Type</option>
                  {uniqueTypes.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={2}>
              <Form.Group controlId="filterYear">
                <Form.Control
                  as="select"
                  name="year"
                  value={filterCriteria.year}
                  onChange={handleFilterChange}
                >
                  <option value="">Select Year</option>
                  {uniqueYears.map((year, index) => (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={1}>
              <Form.Group controlId="filterStatus">
                <Form.Control
                  as="select"
                  name="status"
                  value={filterCriteria.status}
                  onChange={handleFilterChange}
                >
                  <option value="">Status</option>
                  {uniqueStatus.map((status, index) => (
                    <option key={index} value={status}>
                      {status}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label> </Form.Label>
                <BiAnalyse
                  size={30}
                  onClick={(e) =>
                    setFilterCriteria({
                      network: "",
                      advertiser: "",
                      type: "",
                      year: "",
                      status: "",
                    })
                  }
                />
                reset
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </div>
      <Table
        striped
        bordered
        hover
        className="mt-5"
        style={{ backgroundColor: "white" }}
      >
        <thead>
          <tr>
            <th style={{ position: "relative" }}>Network</th>
            <th style={{ position: "relative" }}>Advertiser</th>
            <th
              onClick={() => handleSortChange("type")}
              style={{ cursor: "pointer", position: "relative" }}
            >
              Type
            </th>
            <th style={{ position: "relative" }}>Title</th>
            <th style={{ position: "relative" }}>Publishers</th>
            <th
              onClick={() => handleSortChange("startDate")}
              style={{
                cursor: "pointer",
                position: "relative",
                width: "130px",
              }}
            >
              Start Date
            </th>
            <th
              onClick={() => handleSortChange("endDate")}
              style={{
                cursor: "pointer",
                position: "relative",
                width: "130px",
              }}
            >
              End Date
            </th>
            {/* <th>Year</th> */}
            <th style={{ position: "relative" }}>Status</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {campaigns.map((data, index) => (
            <tr key={index}>
              <td>{data.network.toUpperCase()}</td>
              <td>{getAdvertiserName(data.advertiser)}</td>
              <td>{data.type}</td>
              <td>{data.title}</td>
              <td>
                {data?.selectedPublishers.length === 0
                  ? "All Publishers"
                  : data?.selectedPublishers
                      .map((publisher) => publisher.label)
                      .join(", ")}
              </td>
              <td>{moment(data.startDate).format("DD-MM-YYYY")}</td>
              <td>{moment(data.endDate).format("DD-MM-YYYY")}</td>
              {/* <td>{moment(data.startDate).format("YYYY")}</td> */}
              <td>
                <Link
                  to={{
                    pathname: "/campaignreport",
                    search: `campaignId=${data._id}`,
                  }}
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  {moment().isBefore(moment(data.startDate), "day") ? (
                    <span style={{ color: "red" }}>Not Started</span>
                  ) : moment().isBetween(
                      moment(data.startDate),
                      moment(data.endDate),
                      "day",
                      "[]"
                    ) ? (
                    <span style={{ color: "blue" }}>Running</span>
                  ) : moment().isAfter(moment(data.endDate), "day") ? (
                    <span style={{ color: "green" }}>Completed</span>
                  ) : null}
                </Link>
              </td>
              <td>
                <AddCampaign
                  alladvertiserArray={alladvertiserArray}
                  campaign={data}
                  allnetwork={allnetwork}
                  allpublisher={allpublisher}
                  updatcampaign={updatcampaign}
                />{" "}
              </td>
              <td>
                <div
                  className="nav-item"
                  data-toggle="modal"
                  data-target={`#campaigndeletemodal${data._id}`}
                  style={{ color: "red", fontSize: "18px" }}
                >
                  <BsTrashFill color="red" />
                  <div
                    className="modal fade"
                    id={`campaigndeletemodal${data._id}`}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-body">
                          Advertiser : {getAdvertiserName(data.advertiser)}
                          <br />
                          Type: {data.type}
                          <br />
                          StartDate :{" "}
                          {moment(data.startDate).format("DD-MM-YYYY")}
                          <br />
                          End Date : {moment(data.endDate).format("DD-MM-YYYY")}
                          <hr />
                          Think Again , Are you sure ??
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={(e) => handledeletecampaign(data._id)}
                            data-dismiss="modal"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination>
        {[...Array(totalPages)].reduce((acc, _, index) => {
          const page = index + 1;
          const maxVisiblePages = 3; // Adjust this to control how many pages are shown around the current page

          if (
            page === 1 ||
            page === totalPages ||
            (page >= currentPage - maxVisiblePages &&
              page <= currentPage + maxVisiblePages)
          ) {
            acc.push(
              <Pagination.Item
                key={page}
                active={page === currentPage}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </Pagination.Item>
            );
          } else if (
            page === currentPage - maxVisiblePages - 1 ||
            page === currentPage + maxVisiblePages + 1
          ) {
            acc.push(<Pagination.Ellipsis key={page} />);
          }

          return acc;
        }, [])}
      </Pagination>
    </div>
  );
};

export default Campaign;
