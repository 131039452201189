import React, { useEffect , useState } from 'react';
import { useHistory  } from "react-router-dom";
import jwt from 'jwt-decode';

function Unauthorized(props) {
    const history = useHistory();
    const [ isvalidtoken, setIsvalidtoken ] = useState(0)

    useEffect((e) => {
      if (localStorage.getItem("TOU")) {
        setIsvalidtoken(jwt(localStorage.getItem("TOU")).accessCode);
      }
    }, []);

    const handlehome = (e) => {
      return history.push("/");
    };

    const handlelogin = (e) => {
      localStorage.removeItem("TOU");
      return history.push("/login");
    };
    return (
        <div>
            <center>
                <>
                    <div className="card text-white bg-danger mb-3" style={{maxWidth: "50rem", marginTop: "5rem"}}>
                        <div className="card-header">ERROR</div>
                        <div className="card-body">
                            
                            {
                                (isvalidtoken ===  111 || isvalidtoken=== 222) ?
                                <>
                                    <h5 className="card-title">404 - Page Not Found</h5>
                                    <h6 className="card-title">You have to go back ..!!</h6> <br />
                                    <button type="button" className="btn btn-dark" onClick={handlehome}>Home</button>
                                </>
                                :
                                <>
                                    <h5 className="card-title">UNAUTHORIZED</h5>
                                    <h6 className="card-title">You have to login... see you after successfully Login!!</h6> <br />
                                    <button type="button" className="btn btn-dark" onClick={handlelogin}>Login</button>
                                </>
                            }
                            
                        </div>

                    </div>
                </>
            </center>
        </div>
    );
}

export default Unauthorized;