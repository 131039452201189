import React, { useState, useEffect }   from 'react';
import moment from 'moment'
import { Loading, Grid, Text } from "@nextui-org/react";

// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
import {Bar} from 'react-chartjs-2';
import {} from 'chart.js/auto'

import logo_img from "../images/Online_United_Logo.png";
import {logobaseurl} from "../Services/Http-Common"
import '../Styles/tablestyle.scss' 
import Adv_Pub_DataService from '../Services/advertiserService'


const Data = (props) =>  {
  const [advertiserName, setAdvertiserName] = useState('')
  const [selectedpublisher, setSelectedPublisher] = useState("All Active Publisher")
  const [selectedyear, setSelectedYear] = useState("")
  const [logourl, setLogourl] = useState('')

  const [tokenvalue, setTokenValue] = useState('')
  const [weeknumber, setWeekNumber] = useState(0)
  const [monthnumber, setMonthNumber] = useState(0)

  const [monthBruttoTableDetail, setMonthBruttoTableDetail] = useState([])
  const [monthNettoTableDetail, setMonthNettoTableDetail] = useState([])

  const [monthdataArray, setMonthdataArray] = useState([])
  const [month_approved_turnover, setMonthApprovedTurnover] = useState([])
  const [month_declined_turnover, setMonthDeclinedTurnover] = useState([])
  const [month_pending_turnover, setMonthPendingTurnover] = useState([])

  const [weekTableDetail, setWeekTableDetail] = useState([]);
  const [weeksaleArray, setWeekSaleArray] = useState([]);
  const [week_approved_turnover, setWeekApprovedTurnover] = useState([]);
  const [week_declined_turnover, setWeekDeclinedTurnover] = useState([]);
  const [week_pending_turnover, setWeekPendingTurnover] = useState([]);

  const [advertiserId, setAdvertiserId] = useState("");
  const [topweek, setTopWeek] = useState([]);
  const [topmonth, setTopMonth] = useState([]);

  const [activepublisher, setActivePublisher] = useState([])
  const [years, setYears] = useState([])


  const [isloading, setIsLoading] = useState(true);
  const [errmessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(true);

  useEffect(
    (e) => {
      const queryParams = new URLSearchParams(window.location.search);
      const id = queryParams.get("id");
      const token = queryParams.get("token");

      setAdvertiserId(id);
      setTokenValue(token);

      let Today = moment().format("YYYY-MM-DD");
      if (
        localStorage.getItem("response" + String(id)) &&
        new Date(Today) <
          new Date(
            JSON.parse(localStorage.getItem("response" + String(id))).Monday
          )
      ) {
        var response = localStorage.getItem("response" + String(id));
        if (JSON.parse(response).token === token) {
          setIsError(false);
          setAdvertiserName(JSON.parse(response).advertiserName);
          setLogourl(JSON.parse(response).logourl)
          
          setMonthNettoTableDetail(JSON.parse(response).monthNettoTableDetail)
          setMonthBruttoTableDetail(JSON.parse(response).monthBruttoTableDetail)
          setMonthdataArray(JSON.parse(response).monthdata);
          setMonthApprovedTurnover(
            JSON.parse(response).monthturnover.month_approved_turnover
          );
          setMonthDeclinedTurnover(
            JSON.parse(response).monthturnover.month_declined_turnover
          );
          setMonthPendingTurnover(
            JSON.parse(response).monthturnover.month_pending_turnover
          );

          setWeekTableDetail(JSON.parse(response).weekTableDetail);
          setWeekSaleArray(JSON.parse(response).weekdata);
          setWeekApprovedTurnover(
            JSON.parse(response).weekturnover.week_approved_turnover
          );
          setWeekDeclinedTurnover(
            JSON.parse(response).weekturnover.week_declined_turnover
          );
          setWeekPendingTurnover(
            JSON.parse(response).weekturnover.week_pending_turnover
          );
          setActivePublisher(JSON.parse(response).active_publishers)
          setYears(JSON.parse(response).years)
          setSelectedYear(JSON.parse(response).years[0].year)
          setWeekNumber(0)
          setMonthNumber(0)
          // setTopWeek(JSON.parse(response).weektop);
          // setTopMonth(JSON.parse(response).monthtop);
          setErrorMessage("");
          setIsLoading(false);
        } else {
          setIsError(true);
          setIsLoading(false);
          setErrorMessage("Sorry, We Got Invalid Data. Please try with correct URL.");
        }
      } else {
        localStorage.removeItem("response" + String(id));
        Adv_Pub_DataService.getDataforpdf(id, token)
          .then((response) => {
            if (response.data?.errorMessage) {
              setIsError(true);
              setIsLoading(false);
              setErrorMessage(
                "Sorry, We Got Invalid Data. Please try with correct URL."
              );
            } else {
              setIsError(false);
              setAdvertiserName(response.data.data.advertiserName);
              setLogourl(response.data.data.logourl);

              setMonthNettoTableDetail(
                response.data.data.monthNettoTableDetail
              );
              setMonthBruttoTableDetail(
                response.data.data.monthBruttoTableDetail
              );
              setMonthdataArray(response.data.data.monthdata);
              setMonthApprovedTurnover(
                response.data.data.monthturnover.month_approved_turnover
              );
              setMonthDeclinedTurnover(
                response.data.data.monthturnover.month_declined_turnover
              );
              setMonthPendingTurnover(
                response.data.data.monthturnover.month_pending_turnover
              );

              setWeekTableDetail(response.data.data.weekTableDetail);
              setWeekSaleArray(response.data.data.weekdata);
              setWeekApprovedTurnover(
                response.data.data.weekturnover.week_approved_turnover
              );
              setWeekDeclinedTurnover(
                response.data.data.weekturnover.week_declined_turnover
              );
              setWeekPendingTurnover(
                response.data.data.weekturnover.week_pending_turnover
              );

              setWeekNumber(0);
              setMonthNumber(0);
              // setTopWeek(response.data.data.weektop);
              // setTopMonth(response.data.data.monthtop);

              setActivePublisher(response.data.data.active_publishers);
              setYears(response.data.data.years);
              setSelectedYear(response.data.data.years[0].year);

              setTokenValue(response.data.data.token);
              setErrorMessage("");
              setIsLoading(false);
              localStorage.setItem(
                "response" + String(id),
                JSON.stringify(response.data.data)
              );
            }
          })
          .catch((error) => {
            setIsError(true);
            setIsLoading(false);
            setErrorMessage(
              "Sorry, We Got Invalid Data. Please try with correct URL."
            );
            //alert("Got Error");
          });
      }
    },
    []
  );

  var monthnumberArray = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ]

  const month_state = {
    labels: monthnumberArray,
    datasets: [
      {
        type: "line",
        label: "Monthly Sales",
        fill: false,
        lineTension: 0,
        backgroundColor: "rgb(61, 64, 235)",
        borderColor: "rgb(61, 64, 235)",
        borderWidth: 2,
        data: monthdataArray,
        yAxisID: "y1",
        stack: "y1",
      },
      {
        type: "bar",
        label: "Approved",
        fill: false,
        lineTension: 0.5,
        backgroundColor: "rgba(32,108,32)",
        borderColor: "rgba((32,108,32))",
        borderWidth: 2,
        data: month_approved_turnover,
        yAxisID: "y",
        stack: "x",
      },
      {
        type: "bar",
        label: "Declined",
        fill: false,
        lineTension: 0.5,
        backgroundColor: "rgb(252, 28, 3)",
        borderColor: "rgb((252, 28, 3))",
        borderWidth: 2,
        data: month_declined_turnover,
        yAxisID: "y",
        stack: "x",
      },
      {
        type: "bar",
        label: "Pending",
        fill: false,
        lineTension: 0.5,
        backgroundColor: "rgb(100, 107, 95)",
        borderColor: "rgb((100, 107, 95))",
        borderWidth: 2,
        data: month_pending_turnover,
        yAxisID: "y",
        stack: "x",
      },
    ],
  };

  var weeknumberarray = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
  ];

  const week_state = {
    labels: weeknumberarray,
    datasets: [
      {
        type: "line",
        label: "Weekly Sales",
        fill: false,
        lineTension: 0,
        backgroundColor: "rgb(61, 64, 235)",
        borderColor: "rgb(61, 64, 235)",
        borderWidth: 2,
        data: weeksaleArray,
        yAxisID: "y1",
      },
      {
        type: "bar",
        label: "Approved",
        fill: false,
        lineTension: 0,
        backgroundColor: "rgba(32,108,32)",
        borderColor: "rgba((32,108,32))",
        borderWidth: 2,
        data: week_approved_turnover,
        yAxisID: "y",
        ordered: 1,
      },
      {
        type: "bar",
        label: "Declined",
        fill: false,
        lineTension: 0,
        backgroundColor: "rgb(252, 28, 3)",
        borderColor: "rgb((252, 28, 3))",
        borderWidth: 2,
        data: week_declined_turnover,
        yAxisID: "y",
        ordered: 1,
      },
      {
        type: "bar",
        label: "Pending",
        fill: false,
        lineTension: 0,
        backgroundColor: "rgb(100, 107, 95)",
        borderColor: "rgb((100, 107, 95))",
        borderWidth: 2,
        data: week_pending_turnover,
        yAxisID: "y",
        ordered: 3,
      },
    ],
  };


  const handlegraphbypublisher = (advertiserId, publisherId, publisherName, tokenvalue, selectedyear) => {
    setSelectedPublisher(publisherName)
    Adv_Pub_DataService.getgraphbypublisher(advertiserId, publisherId, tokenvalue, selectedyear).then((response) => {
      setWeekSaleArray(response.data.data.weekdata);
      setWeekApprovedTurnover( response.data.data.weekturnover.week_approved_turnover);
      setWeekDeclinedTurnover( response.data.data.weekturnover.week_declined_turnover);
      setWeekPendingTurnover(response.data.data.weekturnover.week_pending_turnover);

      setMonthdataArray(response.data.data.monthdata);
      setMonthApprovedTurnover(response.data.data.monthturnover.month_approved_turnover);
      setMonthDeclinedTurnover(response.data.data.monthturnover.month_declined_turnover);
      setMonthPendingTurnover(response.data.data.monthturnover.month_pending_turnover);

    }).catch((error) => {
      setIsError(true);
      setIsLoading(false);
      setErrorMessage("Sorry, We Got Invalid Data. Please try with correct URL.")
      //alert("Got Error");
    });
  }


  const handlerequestedyearreport = (advertiserId, tokenvalue, requested_year) => {
    setIsLoading(true);
    setSelectedYear(requested_year)
    setSelectedPublisher("All Active Publisher")
    Adv_Pub_DataService.getDataforpdf(advertiserId, tokenvalue, requested_year).then((response) => {
      if (response.data?.errorMessage) {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage("Sorry, We Got Invalid Data. Please try with correct URL.");
      } else {
        setIsError(false);
        setAdvertiserName(response.data.data.advertiserName);
        setLogourl(response.data.data.logourl)

        setMonthNettoTableDetail(response.data.data.monthNettoTableDetail)
        setMonthBruttoTableDetail(response.data.data.monthBruttoTableDetail)
        setMonthdataArray(response.data.data.monthdata);
        setMonthApprovedTurnover(
          response.data.data.monthturnover.month_approved_turnover
        );
        setMonthDeclinedTurnover(
          response.data.data.monthturnover.month_declined_turnover
        );
        setMonthPendingTurnover(
          response.data.data.monthturnover.month_pending_turnover
        );

        setWeekNumber(0);
        setMonthNumber(0);

        setWeekTableDetail(response.data.data.weekTableDetail);
        setWeekSaleArray(response.data.data.weekdata);
        setWeekApprovedTurnover(
          response.data.data.weekturnover.week_approved_turnover
        );
        setWeekDeclinedTurnover(
          response.data.data.weekturnover.week_declined_turnover
        );
        setWeekPendingTurnover(
          response.data.data.weekturnover.week_pending_turnover
        );

        // setTopWeek(response.data.data.weektop);
        // setTopMonth(response.data.data.monthtop);
        
        setActivePublisher(response.data.data.active_publishers)
        setYears(response.data.data.years)

        setTokenValue(response.data.data.token);
        setErrorMessage("");
        setIsLoading(false);
      }
    }).catch((error) => {
      setIsError(true);
      setIsLoading(false);
      setErrorMessage("Sorry, We Got Invalid Data. Please try with correct URL.")
      //alert("Got Error");
    });

  }

  // const printDocument = (e) => {
  //   class =  id={"divToPrint"+advertiserId}
  //   e.preventDefault()
  //   const input = document.getElementById("divToPrint"+advertiserId);
  //   html2canvas(input)
  //     .then((canvas) => {
  //       let imgWidth = 208;
  //       let imgHeight = canvas.height * imgWidth / canvas.width;
  //       const imgData = canvas.toDataURL('img/png');
  //       const pdf = new jsPDF('p', 'mm', 'a4');
  //       pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
  //       pdf.save("download.pdf");
  //     });
  // }

  return (
    <div>
      {isloading ? (
          <Grid justify="center">
          <center>
            <Text blockquote>Please Wait, We are Generating Report for You.
            <Text blockquote>Thank you for your patience.</Text></Text><Loading type="points" />
          </center>
          </Grid>
      ) : (
        <div>
          {isError === true ? (
            <div class="alert alert-danger" role="alert">
              {errmessage}
            </div>
          ) : (
            <div style={{backgroundColor: "white"}}>
              <div >
                <div style={{paddingTop: "1rem", backgroundColor: 'white'}} className='d-flex justify-content-between'>
                    <div>
                      <img src={logo_img} alt="logo img" style={{ width: "15rem" }} />
                    </div>
                    <div>
                      <h1 style={{paddingTop: "1rem"}}>{advertiserName}</h1>
                    </div>
                    <div>
                      <img src={logobaseurl+ logourl} alt="logo img" style={{ width: "15rem" }} />
                    </div>
                </div>
              </div>
              <hr/>
              <div className='row' style={{margin: "1rem"}}>
                <div className="dropdown dropright col">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {selectedpublisher}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenu2" style={{overflowY: "auto", maxHeight: "500px"}}>
                <button class="dropdown-item" type="button"  onClick={(e) => handlegraphbypublisher(advertiserId, 0, "All Active Publisher", tokenvalue, selectedyear)}>All Active Publisher</button>
                  {
                    activepublisher.map((activepublisher, index) =>{
                      return <button key={index} className="dropdown-item " type="button" onClick={(e) => handlegraphbypublisher(advertiserId, activepublisher.publisherId, activepublisher.publisherName, tokenvalue, selectedyear)}><div className='row'><div className='col'>{activepublisher.publisherName}</div><div className='col-auto'>{activepublisher.count}</div></div></button>
                    })
                  }
                </div>
                </div>
                <div className="dropdown  col-auto " >
                <button className="btn btn-secondary  dropdown-toggle" type="button" id="dropdownMenu3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Year - {selectedyear}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenu3"  style={{overflowY: "auto", maxHeight: "200px"}}>
                
                  {
                    years.map((year, index) =>{
                      return <button key={index} className="dropdown-item" type="button" onClick={(e) => handlerequestedyearreport(advertiserId, tokenvalue, year.year)} >
                        Year - {year.year}
                        </button>
                    })
                  }
                </div> 
                </div>  
              </div>
              
              <div>
                <hr />
                <div>
                  <div className="row">
                    <div className="col-md-auto" style={{ width: "70vw" }}>
                      <Bar
                        data={week_state}
                        options={{
                          plugins: {
                            title: {
                              display: true,
                              text: "Weekly Details",
                            },
                          },
                          responsive: true,
                          scales: {
                            x: {
                              stacked: true,
                            },
                            y: {
                              min: 0,
                              ticks: {
                                beginAtZero: true,
                              },
                              grid: {
                                drawOnChartArea: false,
                              },
                              position: "right",
                              stacked: true,
                            },
                          },
                          y1: {
                            min: 0,
                            ticks: {
                              beginAtZero: true,
                            },
                            grid: {
                              drawOnChartArea: false,
                            },
                            position: "left",
                          },
                          onClick: function (evt, item) {
                            setWeekNumber(item[0].index + 1);
                            Adv_Pub_DataService.Toptenbyweeknumber(
                              item[0].index + 1,
                              advertiserId,
                              selectedyear,
                              tokenvalue
                            ).then((response) => {
                              setTopWeek(response.data.data.weektop);
                            }).catch((err) => {
                              setIsError(true);
                              setIsLoading(false);
                              setErrorMessage("Sorry, We Got Invalid Data. Please try with correct URL.")
                            });;
                          },
                        }}
                      />
                    </div>
                    <div className="col" style={{ marginTop: "5vh" }}>
                      <h6>Note: Click on graph to see Top Publisher in Week.</h6><br />
                      {
                        weeknumber===0 ?
                        <>
                        </>
                        :
                        <>
                        <div className="table-responsive">
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th colSpan="3" style={{ color: "green" }}>
                                      Top Publisher in Woche {weeknumber}
                                    </th>
                                  </tr>
                                  <tr></tr>
                                  <tr></tr>
                                </thead>
                         {
                            topweek.length ? 
                            <>
                            
                              <tr>
                                <th scope="col">Index</th>
                                <th scope="col">Publisher Name</th>
                                <th scope="col">Sales</th>
                              </tr>
                              <tbody>
                                {topweek.map((publisher, index) => {
                                  return (
                                    <tr key={index} style={{cursor: "pointer"}} onClick={(e) => handlegraphbypublisher(advertiserId, publisher.publisherId, publisher.publisherName, tokenvalue, selectedyear)}>
                                      <th scope="row">{index + 1}</th>
                                      <td>{publisher.publisherName}</td>
                                      <td>{publisher.count}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                              

                            </>
                            :
                            <div style={{color: "red", marginTop: "1rem", marginLeft: "0.7rem"}}>Sorry, No Publisher Found!</div>
                          }
                          </table>
                              </div>
                        </>
                      }
                         
                          
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-auto" style={{ width: "70vw" }}>
                    <Bar
                      style={{ fontSize: "30px" }}
                      data={month_state}
                      options={{
                        plugins: {
                          title: {
                            display: true,
                            text: "Monthly Details",
                          },
                        },
                        responsive: true,
                        scales: {
                          x: {
                            stacked: true,
                          },
                          y: {
                            grid: {
                              drawOnChartArea: false,
                            },
                            position: "right",
                            stacked: true,
                          },
                          y1: {
                            position: "left",
                            stacked: true,
                          },
                        },
                        onClick: function (evt, item) {
                          setMonthNumber(item[0].index + 1);
                          Adv_Pub_DataService.Toptenbymonthnumber(
                            item[0].index + 1,
                            advertiserId,
                            selectedyear,
                            tokenvalue
                          ).then((response) => {
                            setTopMonth(response.data.data.monthtop);
                          }).catch((err) => {
                            setIsError(true);
                            setIsLoading(false);
                            setErrorMessage("Sorry, We Got Invalid Data. Please try with correct URL.")
                          });
                        },
                      }}
                    />
                  </div>

                  <div className="col" style={{ marginTop: "5vh" }}>
                  <h6>Note: Click on graph to see Top Publisher in Month.</h6><br />
                    {
                      monthnumber===0 ?
                      <>
                      </>
                      :
                      <>
                      <div className="table-responsive  ">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th colSpan="3" style={{ color: "green" }}>
                                Top Publisher im {
                                  monthnumberArray[monthnumber-1]
                                }
                              </th>
                            </tr>
                            <tr></tr>
                            <tr></tr>
                          </thead>
                          {
                            topmonth.length ? 
                            <>            
                              <tr>
                                <th scope="col">Index</th>
                                <th scope="col">Publisher Name</th>
                                <th scope="col">Sales</th>
                              </tr>
                              <tbody>
                                {topmonth.map((publisher, index) => {
                                  return (
                                    <tr key={index} style={{cursor: "pointer"}} onClick={(e) => handlegraphbypublisher(advertiserId, publisher.publisherId, publisher.publisherName, tokenvalue, selectedyear)}>
                                      <th scope="row">{index + 1}</th>
                                      <td>{publisher.publisherName}</td>
                                      <td>{publisher.count}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </>
                            :
                            <div style={{color: "red", marginTop: "1rem", marginLeft: "0.7rem"}}>Sorry, No Publisher Found!</div>
                          }
                          </table>
                        </div>
                      </>
                    }
                        
                     
                  </div>
                </div>

                <div
                  className="row"
                  style={{ margin: "20px", border: "2px" }}
                ></div>
              </div>
              <hr />
              <h1>Weekly overview</h1>
              {
                weekTableDetail.length ?
                <div className="overflow-auto" style={{ width: "97vw" }}>
                  <table className="rwd-table">
                    <col style={{ width: "20px" }} />
                    <col style={{ width: "20px" }} />
                    <col style={{ width: "20px" }} />
                    <thead>
                      <tr>
                        <th style={{ position: 'sticky', left: 0, backgroundColor: '#34495E', zIndex: true}}>Woche</th>
                        {weekTableDetail.map((week, index) => {
                          return (
                            <td  data-th="Genre" key={index}>
                              {week.weeknumber}
                            </td>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th className='left-column-stiky'>Clicks</th>
                        {weekTableDetail.map((week, index) => {
                          return (
                            <td data-th="Genre" key={index}>
                              {new Intl.NumberFormat('de-DE').format(week.clicks)}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th className='left-column-stiky'>Sales</th>
                        {weekTableDetail.map((week, index) => {
                          return (
                            <td key={index} data-th="Genre">
                              {new Intl.NumberFormat('de-DE').format(week.sales)}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th className='left-column-stiky'>Turnover</th>
                        {weekTableDetail.map((week, index) => {
                          return (
                            <td key={index} data-th="Genre">
                              {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(week.turnover)}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th className='left-column-stiky'>Publisher Commission</th>
                        {weekTableDetail.map((week, index) => {
                          return (
                            <td key={index} data-th="Genre">
                              {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(week.publisher_commission)}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th className='left-column-stiky'>Network Commission</th>
                        {weekTableDetail.map((week, index) => {
                          return (
                            <td key={index} data-th="Genre">
                              {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(week.network_commission)}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th className='left-column-stiky'>WK</th>
                        {weekTableDetail.map((week, index) => {
                          return (
                            <td key={index} data-th="Genre">
                              {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(week.wk)}
                            </td>
                          );
                        })}
                      </tr>
                    </tbody>
                  </table>
                </div>
                :
                <div style={{color: "red", marginTop: "1rem", marginLeft: "0.7rem"}}>Sorry, Week Data not found !!</div>              
              }
              <hr />
              <h1>Monthly overview</h1>
              {
                monthBruttoTableDetail.length ?
                <div className="overflow-auto" style={{ width: "97vw" }}>
                  <table className="rwd-table">
                    <col style={{ width: "20px" }} />
                    <col style={{ width: "20px" }} />
                    <col style={{ width: "20px" }} />
                    <thead>
                      <tr>
                        <th className='left-column-stiky'>Brutto</th>
                        {monthBruttoTableDetail.map((month, index) => {
                          return (
                            <td data-th="Genre" key={index}>
                              {month.monthnumber}
                            </td>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th className='left-column-stiky'>Views</th>
                        {monthBruttoTableDetail.map((month, index) => {
                          return (
                            <td data-th="Genre" key={index}>
                              {new Intl.NumberFormat('de-DE').format(month.views)}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th className='left-column-stiky'>Clicks</th>
                        {monthBruttoTableDetail.map((month, index) => {
                          return (
                            <td data-th="Genre" key={index}>
                              {new Intl.NumberFormat('de-DE').format(month.clicks)}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th className='left-column-stiky'>Sales</th>
                        {monthBruttoTableDetail.map((month, index) => {
                          return (
                            <td data-th="Genre" key={index}>
                              {new Intl.NumberFormat('de-DE').format(month.sales)}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th className='left-column-stiky'>Turnover</th>
                        {monthBruttoTableDetail.map((month, index) => {
                          return (
                            <td data-th="Genre" key={index}>
                              {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(month.turnover)}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th className='left-column-stiky'>Publisher Commission</th>
                        {monthBruttoTableDetail.map((month, index) => {
                          return (
                            <td data-th="Genre" key={index}>
                              {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(month.publisher_commission)}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th className='left-column-stiky'>Network Commission</th>
                        {monthBruttoTableDetail.map((month, index) => {
                          return (
                            <td data-th="Genre" key={index}>
                              {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(month.network_commission)}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th className='left-column-stiky'>Agency Commission</th>
                        {monthBruttoTableDetail.map((month, index) => {
                          return (
                            <td data-th="Genre" key={index}>
                              {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(month.agency_commission)}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th className='left-column-stiky'>Total Cost</th>
                        {monthBruttoTableDetail.map((month, index) => {
                          return (
                            <td data-th="Genre" key={index}>
                              {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(month.total_cost)}
                            </td>
                          );
                        })}
                      </tr> 
                    </tbody>
                  </table>
                </div>
                :
                <div style={{color: "red", marginTop: "1rem", marginLeft: "0.7rem"}}>Sorry, Brutto Table not found !!</div>   
              }
             

              {
                monthNettoTableDetail.length ?
                <div className="overflow-auto" style={{ width: "97vw" }}>
                  <table className="rwd-table">
                    <col style={{ width: "20px" }} />
                    <col style={{ width: "20px" }} />
                    <col style={{ width: "20px" }} />
                    <thead>
                      <tr>
                        <th className='left-column-stiky'>Netto</th>
                        {monthNettoTableDetail.map((month, index) => {
                          return (
                            <td data-th="Genre" key={index}>
                              {month.month}
                            </td>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th className='left-column-stiky'>Sales</th>
                        {monthNettoTableDetail.map((month, index) => {
                          return (
                            <td data-th="Genre" key={index}>
                              {new Intl.NumberFormat('de-DE').format(month.sales)}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th className='left-column-stiky'>Turnover</th>
                        {monthNettoTableDetail.map((month, index) => {
                          return (
                            <td data-th="Genre" key={index}>
                              {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(month.turnover)}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th className='left-column-stiky'>Publisher Commission</th>
                        {monthNettoTableDetail.map((month, index) => {
                          return (
                            <td data-th="Genre" key={index}>
                              {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(month.publisher_commission)}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th className='left-column-stiky'>Network Commission</th>
                        {monthNettoTableDetail.map((month, index) => {
                          return (
                            <td data-th="Genre" key={index}>
                              {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(month.network_commission)}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th className='left-column-stiky'>Agency Commission</th>
                        {monthNettoTableDetail.map((month, index) => {
                          return (
                            <td data-th="Genre" key={index}>
                              {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(month.agency_commission)}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th className='left-column-stiky'>Total Cost</th>
                        {monthNettoTableDetail.map((month, index) => {
                          return (
                            <td data-th="Genre" key={index}>
                              {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(month.total_cost)}
                            </td>
                          );
                        })}
                      </tr> 
                      <tr>
                        <th className='left-column-stiky'>Warenkorb</th>
                        {monthNettoTableDetail.map((month, index) => {
                          return (
                            <td data-th="Genre" key={index}>
                              {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(month.warenkorb)}
                            </td>
                          );
                        })}
                      </tr> 
                      <tr>
                        <th className='left-column-stiky'>CPO</th>
                        {monthNettoTableDetail.map((month, index) => {
                          return (
                            <td data-th="Genre" key={index}>
                              {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(month.cpo)}
                            </td>
                          );
                        })}
                      </tr> 
                      <tr>
                        <th className='left-column-stiky'>KUR</th>
                        {monthNettoTableDetail.map((month, index) => {
                          return (
                            <td data-th="Genre" key={index}>
                              {new Intl.NumberFormat('de-DE').format(month.kur)} %
                            </td>
                          );
                        })}
                      </tr> 
                      <tr>
                        <th className='left-column-stiky'>Stornoquote</th>
                        {monthNettoTableDetail.map((month, index) => {
                          return (
                            <td data-th="Genre" key={index}>
                              {new Intl.NumberFormat('de-DE').format(month.stornoquote)} %
                            </td>
                          );
                        })}
                      </tr> 
                    </tbody>
                  </table>
                </div>
                :
                <div style={{color: "red", marginTop: "1rem", marginLeft: "0.7rem"}}>Sorry, Netto Table not found !!</div>
              }

              <hr />
            </div>
          )}
        </div>
      )}
    </div>
  );
    
}

// {/* <center>
//   <button className='btn btn-dark' onClick={printDocument}>Download</button>
// </center> */}

export default Data;