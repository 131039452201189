import React, { useState, useRef, useEffect } from "react";

import Navbar from "./Navigationbar";
import brevoService from "../Services/brevoService";
import publisherService from "../Services/publisherService";
import Adv_Pub_DataService from "../Services/advertiserService";

function Brevoupload() {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [url, setUrl] = useState("");
  const [email, setEmail] = useState("");
  const [language, setLanguage] = useState("");
  // const [account, setAccount] = useState("");
  const [network, setNetwork] = useState("");
  const [allnetwork, setAllNetwork] = useState([]);
  const [advertiserArray, setAdvertiserArray] = useState([]);
  const [advertiserfornewpublisher, setAdvertiserforNewPublisher] = useState();
  const [selectedAdvertiser, setSelectedAdvertiser] = useState();
  const [lastcsvcontactuploaddate, setLastcsvcontactuploaddate] = useState();
  const [loading, setLoading] = useState(false); // State to track loading status
  const [progressPercentage, setProgressPercentage] = useState(0); // State to track progress percentage
  const [awinuploadmsg, setAwinuploadmsg] = useState("");

  useEffect((e) => {
    publisherService.getallnetwork().then((response) => {
      if (response.status === 200) {
        setAllNetwork(response.data.allnetwork[0].allnetwork);
      }
    });
    Adv_Pub_DataService.getAllAdvertiser().then((response) => {
      if (response.status === 200) {
        setAdvertiserArray(response.data.data);
      }
    });
  }, []);
  const [awinPublishercsvData, setAwinPublisherCsvData] = useState([]);
  const awinpublisherfileInputRef = useRef(null);

  const [csvData, setCsvData] = useState([]);
  const fileInputRef = useRef(null); // Reference to file input element

  const [err_msg, setErr_Msg] = useState("");

  const downloadCSV = () => {
    // Prepare data for CSV
    const csvData = [
      [
        "firstname",
        "lastname",
        "gender",
        "url",
        "email",
        "language",
        "advertiser",
        "network",
      ],
      // Add your data rows here
    ];

    // Convert data to CSV format
    const csvContent = csvData.map((row) => row.join(",")).join("\n");

    // Create a Blob containing the CSV data
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Create a link element to trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "data.csv";
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up
    document.body.removeChild(link);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      const fileType = fileName.slice(fileName.lastIndexOf("."));
      if (fileType === ".csv") {
        const reader = new FileReader();
        reader.onload = (event) => {
          const result = event.target.result;
          const lines = result.split("\n");
          const header = lines[0].split(",").map((item) => item.trim()); // Split header by tab and trim whitespace
          const data = lines
            .slice(1)
            .filter((line) => {
              const values = line.split(",").map((item) => item.trim()); // Split each row by tab and trim whitespace
              return values.every((value, index) => value && header[index]); // Check if all fields are filled
            })
            .map((line) => {
              const values = line.split(",").map((item) => item.trim()); // Split each row by tab and trim whitespace
              const obj = {};
              header.forEach((item, index) => {
                obj[item] = values[index]; // Assign values to object properties
              });
              return obj;
            });
          setCsvData(data);
        };
        reader.readAsText(file);
      } else {
        alert("Only .xlsx and .csv files are allowed."); // Display error message for invalid file type
      }
    }
  };

  const handleAwinPublisherFileUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      const fileName = file.name;
      const fileType = fileName.slice(fileName.lastIndexOf("."));
      if (fileType === ".csv") {
        const reader = new FileReader();
        reader.onload = (event) => {
          const result = event.target.result;
          let rows = result
            .trim("\r")
            .split("\n")
            .map((row) => row.replace(/"/g, ""))
            .map((row) => row.replace(/\r/g, ""));
          // Extract header fields
          const header = rows[0].split("\r").map((item) => item.trim());
          // Extract data rows starting from index 1
          const headervalue = header[0].split(",").map((item) => item.trim());
          const data = rows
            .map((row, rowindex) => {
              if (rowindex === 0) return null; // Skip processing the header row
              const values = row.split(",").map((item) => item.trim());
              const obj = {};
              headervalue.forEach((key, index) => {
                obj[key] = values[index];
              });
              return obj;
            })
            .filter((row) => row !== null); // Remove null entries representing the header row

          // You can now use the 'data' array containing objects with keys based on the header
          setAwinPublisherCsvData(data);
        };
        reader.readAsText(file);
      }
    } else {
      alert("only .csv files are allowed."); // Display error message for invalid file type
    }
  };

  const handleSubmit = async () => {
    if (
      firstname !== "" &&
      lastname !== "" &&
      gender !== "" &&
      url !== "" &&
      email !== "" &&
      language !== "" &&
      advertiserfornewpublisher &&
      network
    ) {
      const advertiser = advertiserfornewpublisher;
      const userInfo = [
        {
          firstname,
          lastname,
          gender,
          url,
          email,
          language,
          advertiser,
          network,
        },
      ];
      await brevoService
        .uploadcontacts(userInfo)
        .then((response) => {
          if (response.status === 200) {
            setErr_Msg("");
            setFirstName("");
            setLastName("");
            setGender("");
            setUrl("");
            setEmail("");
            setLanguage("");
            setAdvertiserforNewPublisher("");
            setNetwork("");
          } else if (response.status === 400) {
            alert("Error: Duplicate Data !!");
          }
        })
        .catch((err) => {
          alert("Something went wrong !!");
        });
    } else {
      setErr_Msg("Fill all the fields !!");
    }
  };

  const handleCSVSubmit = async () => {
    if (csvData.length > 0) {
      // Assuming csvData is the array of objects you provided
      // const formattedData = csvData.map((item) => {
      //   const data = item;
      //   console.log(data);
      // });
      await brevoService
        .uploadcontacts(csvData)
        .then((response) => {
          if (response.status === 200) {
            setErr_Msg("");
            setAwinPublisherCsvData([]);
            // Reset file input value after submission
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
          } else if (response.status === 400) {
            alert("Error: Duplicate Data !!");
          }
        })
        .catch((err) => {
          setErr_Msg("Something went wrong !!");
        });
    }
  };

  const handleAwinPublisherCSVSubmit = async () => {
    if (selectedAdvertiser !== "") {
      setAwinuploadmsg("");
      if (awinPublishercsvData.length > 0) {
        const chunkSize = 100; // Adjust the chunk size as needed
        const totalChunks = Math.ceil(awinPublishercsvData.length / chunkSize);
        let chunksProcessed = 0; // Track the number of processed chunks

        setLoading(true); // Show loader when processing starts

        for (let i = 0; i < totalChunks; i++) {
          const start = i * chunkSize;
          const end = Math.min(
            (i + 1) * chunkSize,
            awinPublishercsvData.length
          );
          const chunkData = awinPublishercsvData.slice(start, end);

          try {
            const response = await brevoService.uploadawinpublishercsv(
              selectedAdvertiser,
              chunkData
            );
            if (response.status === 200) {
              chunksProcessed++;
              const percentage = Math.floor(
                (chunksProcessed / totalChunks) * 100
              );
              setProgressPercentage(percentage); // Update progress percentage
              // Chunk uploaded successfully
              if (i === totalChunks - 1) {
                // Assuming selectedAdvertiser contains the ID of the selected advertiser
                let updatedAdvertiserArray = [...advertiserArray]; // Create a copy of the array

                // Find the index of the object with the matching selectedAdvertiser
                const index = updatedAdvertiserArray.findIndex(
                  (advertiser) =>
                    Number(advertiser.advertiserId) ===
                    Number(selectedAdvertiser)
                );

                // If the index is found
                if (index !== -1) {
                  // Update the ispublishercsvuploaded field of the matched object to true
                  updatedAdvertiserArray[index].ispublishercsvuploaded = true;
                  setAdvertiserArray(updatedAdvertiserArray);
                }
                setAwinPublisherCsvData([]);
                setSelectedAdvertiser("");
                // Reset file input value after submission
                if (awinpublisherfileInputRef.current) {
                  awinpublisherfileInputRef.current.value = "";
                }
                setLoading(false); // Hide loader

                // Last chunk uploaded, show success message
                setAwinuploadmsg("Successfully updated..!!");
              }
            }
          } catch (error) {
            setLoading(false); // Hide loader
            setAwinuploadmsg("Please upload CSV file with proper format !!");
            return;
          }
        }
      }
    } else {
      setLoading(false); // Hide loader
      setAwinuploadmsg("Please select valid Advertiser from List !!");
      return;
    }
  };

  return (
    <>
      <Navbar />
      <div className="container">
        <br />
        <h3>
          AWIN PUBLISHER - UPLOAD <br />
          <span style={{ color: "red", fontSize: "15px" }}>
            Language - (English UK) - .csv files only
          </span>
        </h3>
        <br />
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  {/* <h4>Select Advertiser:</h4> */}
                  <select
                    className="form-select"
                    value={selectedAdvertiser}
                    onChange={(e) => {
                      setSelectedAdvertiser(e.target.value);
                      const advertiser = advertiserArray.find(
                        (advertiser) =>
                          Number(advertiser.advertiserId) ===
                          Number(e.target.value)
                      );

                      if (advertiser) {
                        // Extract the value of lastcsvcontactuploaddate from the found object
                        const lastCsvUploadDate =
                          advertiser?.lastcsvcontactuploaddate;

                        // Set the value
                        setLastcsvcontactuploaddate(lastCsvUploadDate);
                        setAwinuploadmsg("");
                      } else {
                        // Set the value
                        setLastcsvcontactuploaddate("");
                      }
                    }}
                  >
                    <option value="">Select Advertiser</option>
                    {advertiserArray.map((advertiser) => {
                      // Check if advertiser is active and network is "awin"
                      if (
                        advertiser.isActive &&
                        advertiser.network === "awin" &&
                        advertiser.ispublishercsvuploaded === false
                      ) {
                        return (
                          <option
                            key={advertiser.advertiserId}
                            value={advertiser.advertiserId}
                          >
                            {advertiser.advertiserName}
                          </option>
                        );
                      }
                      return null; // Return null if condition not met
                    })}
                  </select>
                </div>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <input
                        ref={awinpublisherfileInputRef}
                        type="file"
                        className="form-control"
                        accept=".csv"
                        onChange={handleAwinPublisherFileUpload}
                      />
                    </div>
                    {loading ? ( // Show loader if loading state is true
                      <div className="col-md-6 mb-3">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Updating...</span>
                        </div>
                        <div>Updating {progressPercentage}%</div>
                      </div>
                    ) : (
                      <div className="col-md-5 mb-3">
                        <button
                          className="btn btn-success w-100"
                          onClick={() => {
                            // Ask for confirmation before proceeding
                            const confirmed = window.confirm(
                              "Please cross check before you confirm....!!!! Are you sure??"
                            );

                            // If user confirms, call the handleAwinPublisherCSVSubmit function
                            if (confirmed) {
                              handleAwinPublisherCSVSubmit();
                            }
                          }}
                        >
                          Upload Awin Publishers
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {awinuploadmsg !== "" ? (
                <div>
                  <h5
                    style={{
                      color:
                        awinuploadmsg === "Successfully updated..!!"
                          ? "green"
                          : "red",
                    }}
                  >
                    {awinuploadmsg}
                  </h5>
                </div>
              ) : (
                <div>
                  {lastcsvcontactuploaddate && lastcsvcontactuploaddate && (
                    <h5>Last updated : - {lastcsvcontactuploaddate}</h5>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <hr />
        <br />
        <h2>New Publisher Contact Information</h2>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="firstName" className="form-label">
              First Name *
            </label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              value={firstname}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="lastName" className="form-label">
              Last Name *
            </label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              value={lastname}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="gender" className="form-label">
              Gender *
            </label>
            <select
              className="form-select"
              id="gender"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              required
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>

          <div className="col-md-6 mb-3">
            <label htmlFor="url" className="form-label">
              URL *
            </label>
            <input
              type="text"
              className="form-control"
              id="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              required
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="email" className="form-label">
              Email *
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="language" className="form-label">
              Language *{" "}
              <span style={{ fontSize: "13px" }}>Example.- EN, DE, FR</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="language"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              required
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="account" className="form-label">
              Advertiser *
            </label>
            <select
              className="form-select"
              value={advertiserfornewpublisher}
              onChange={(e) => {
                setAdvertiserforNewPublisher(e.target.value);
              }}
            >
              <option value="">Select Advertiser</option>
              {advertiserArray.map((advertiser) => {
                return (
                  <option
                    key={advertiser.advertiserId}
                    value={advertiser.advertiserId}
                  >
                    {advertiser.advertiserName}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="col-md-6 mb-3">
            <label htmlFor="network" className="form-label">
              Network *
            </label>
            <select
              className="form-select"
              id="network"
              value={network}
              onChange={(e) => setNetwork(e.target.value)}
              required
            >
              <option value="">Select Network</option>
              {allnetwork.map((network, index) => (
                <option key={index} value={network} tabIndex={index}>
                  {network}
                </option>
              ))}
            </select>
          </div>
          {err_msg ? <h5 style={{ color: "red" }}>{err_msg}</h5> : <></>}
          <button className="btn btn-success" onClick={handleSubmit}>
            Submit Invited Publisher
          </button>
        </div>
        <br />
        <h2>Upload CSV File for invited Publishers</h2>
        <span
          style={{ color: "red", fontSize: "15px", cursor: "pointer" }}
          onClick={downloadCSV}
        >
          Download Template of .CSV file
        </span>
        <br />
        <span style={{ color: "red", fontSize: "15px" }}>
          Please separate value by "," and all fields are required. if you not
          enter all details for "row" then that row will not be consider.
        </span>
        <div className="row">
          <div className="col-md-9 mb-3">
            <input
              ref={fileInputRef}
              type="file"
              className="form-control"
              accept=".csv"
              onChange={handleFileUpload}
            />
          </div>
          <div className="col-md-3 mb-3">
            <button className="btn btn-success w-100" onClick={handleCSVSubmit}>
              Submit Invited Publishers List
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Brevoupload;
