import http from './Http-Common'


class AdminDataservice {
  login(value) {
    return http.post("/admin/login", { value });
  }

  getuserlist() {
    return http.get("/admin/getalluser");
  }

  getadvertiserforuser(username, action, advertiserId) {
    return http.post("/admin/getadvertiserforuser", {
      username,
      action,
      advertiserId,
    });
  }

  edituserdata(username, isactive, superpower, role) {
    return http.post("/admin/edituserdata", {
      username,
      isactive,
      superpower,
      role,
    });
  }
}

export default new AdminDataservice()