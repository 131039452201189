import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Loading,
  Grid,
  Radio,
  Modal,
  useModal,
  Button,
  Popover,
} from "@nextui-org/react";

import { AiOutlineCheck } from "react-icons/ai";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

import "reactjs-popup/dist/index.css";
import { trackPromise } from "react-promise-tracker";
import Adv_Pub_DataService from "../Services/advertiserService";
import publisherService from "../Services/publisherService";

import Navbar from "./Navigationbar";

ChartJS.register(ArcElement, Tooltip, Legend);

// function Detail(props) {
//   const history = useHistory();

//   const [advertiserArray, setAdvertiserArray] = useState([]);
//   // click event handler
//   const handleclick = (e) => {
//     e.preventDefault();
//     setAdvertiserArray([]);
//     const value = {
//       publisherId: props.publisherId,
//       startDate: props.startDate,
//       endDate: props.endDate,
//     };

//     trackPromise(
//       Adv_Pub_DataService.getAdvertiser(value)
//         .then((response) => {
//           if (response.status === 200) {
//             setAdvertiserArray(response.data.data);
//           } else {
//             localStorage.removeItem("TOU");
//             return history.push("/Unauthorised");
//           }
//         })
//         .catch((error) => {
//           localStorage.removeItem("TOU");
//           return history.push("/Unauthorised");
//         })
//     );
//   };

//   return (
//     <div>
//       <button
//         type="button"
//         className="btn btn-outline-dark"
//         data-toggle="modal"
//         data-target={"#detail" + props.publisherId}
//         onClick={handleclick}
//       >
//         Connected with
//       </button>
//       <div
//         className="modal fade"
//         id={"detail" + props.publisherId}
//         tabIndex="-1"
//         role="dialog"
//         aria-labelledby="myLargeModalLabel"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog modal-lg">
//           <div className="modal-content">
//             <center style={{ color: "green" }}>{props.publisherName}</center>
//             <b style={{ marginLeft: "10px" }}>
//               {advertiserArray.length} - Advertiser Found{" "}
//             </b>
//             <table className="table table-striped">
//               <thead>
//                 <tr>
//                   <th scope="col">Index</th>
//                   <th scope="col">Advertiser Id</th>
//                   <th scope="col">Advertiser Name</th>
//                   <th scope="col">Ratio</th>
//                   <th scope="col">Sales</th>
//                   {/* <th>Detail</th> */}
//                 </tr>
//               </thead>

//               <tbody>
//                 {advertiserArray.map((advertiser, index) => {
//                   return (
//                     <tr key={index}>
//                       <th scope="row">{index + 1}</th>
//                       <td>{advertiser.advertiserId}</td>
//                       <td>{advertiser.advertiserName}</td>
//                       <td>{parseFloat(advertiser.ratio).toFixed(2)}</td>
//                       <td>{advertiser.count}</td>
//                       {/* <td>
//                         <button
//                           className="btn btn-success"
//                           data-dismiss="modal"
//                           onClick={() => {
//                             props.sendAdvertiserData({
//                               advertiserId: advertiser.advertiserId,
//                             });
//                           }}
//                         >
//                           Check
//                         </button>
//                       </td> */}
//                     </tr>
//                   );
//                 })}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

function Adv_Pub(props) {
  const history = useHistory();
  const location = useLocation();

  const advertiserId = location?.state?.advertiserId;
  const advertiserName = location?.state?.advertiserName;

  const [startDate, setStartDate] = useState(location.state?.startDate);
  const [endDate, setEndDate] = useState(location.state?.endDate);
  const [errorMessage, setErrorMessage] = useState();
  const [state, setState] = useState("Active");
  const [activeLoader, setActiveLoader] = useState(false);
  // const [advertiserArray, setAdvertiserArray] = useState([]);
  const [activepublisherArray, setActivePublisherArray] = useState([]);
  const [partiallyactivepublisherArray, setPartiallyActivePublisherArray] =
    useState([]);
  const [inactivepublisherArray, setInActivePublisherArray] = useState([]);
  const [allnetwork, setAllNetwork] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("All");

  const allmotherpublishertype = [
    "Subnetwork",
    "Cashback/Loyalty",
    "Content",
    "Social Media",
    "CSS",
    "Search",
    "Display",
    "Coupon/Rebate",
    "Price-Comparison/Shopping Engine",
    "Retargeting/Prospecting",
    "Onsite/Tech/Bounce",
    "Internal",
  ];

  const { setVisible, bindings } = useModal();

  let cakepublishername = [];
  let cakepublishersale = [];

  activepublisherArray.forEach((pub) => {
    cakepublishername.push(pub.publishermothername);
    cakepublishersale.push(pub.count);
  });

  useEffect(
    (e) => {
      publisherService
        .getallnetwork()
        .then((response) => {
          if (response.status === 200) {
            setAllNetwork(response.data.allnetwork);
          } else {
            localStorage.removeItem("TOU");
            return history.push("/Unauthorised");
          }
        })
        .catch((err) => {
          if (err.response.status === 403) {
            localStorage.removeItem("TOU");
            return history.push("/Unauthorised");
          }
        });

      // CALL API to find Publishers
      const value = {
        advertiserId: advertiserId,
        startDate: startDate,
        endDate: endDate,
      };

      setErrorMessage("");
      setActivePublisherArray([]);
      setPartiallyActivePublisherArray([]);
      setInActivePublisherArray([]);
      setActiveLoader(true);

      if (value.startDate === "" && value.endDate === "") {
        if (value.advertiserId === "All") {
          setErrorMessage("Please Enter Date..!!");
        } else {
          setErrorMessage("");
          trackPromise(
            Adv_Pub_DataService.getAllPublisher(value)
              .then((response) => {
                if (response.status === 200) {
                  if (response.data.message === "") {
                    // response.data.data.sort((a, b) => (a.count < b.count ? 1 : -1));
                    setActivePublisherArray(response.data.data.active);
                    setPartiallyActivePublisherArray(
                      response.data.data.partiallyactive
                    );
                    setInActivePublisherArray(response.data.data.inactive);
                    setErrorMessage("");
                    setActiveLoader(false);
                  } else {
                    setErrorMessage(response.data.message);
                  }
                } else {
                  localStorage.removeItem("TOU");
                  return history.push("/Unauthorised");
                }
              })
              .catch((error) => {
                localStorage.removeItem("TOU");
                return history.push("/Unauthorised");
              })
          );
        }
      } else if (value.startDate !== "" && value.endDate !== "") {
        if (new Date(value?.startDate) <= new Date(value?.endDate)) {
          setErrorMessage("");
          trackPromise(
            Adv_Pub_DataService.getAllPublisher(value)
              .then((response) => {
                if (response.status === 200) {
                  if (response.data.message === "") {
                    // response.data.data.sort((a, b) => (a.count < b.count ? 1 : -1));
                    setActivePublisherArray(response.data.data.active);
                    setPartiallyActivePublisherArray(
                      response.data.data.partiallyactive
                    );
                    setInActivePublisherArray(response.data.data.inactive);
                    setErrorMessage("");
                    setActiveLoader(false);
                  } else {
                    setErrorMessage(response.data.message);
                  }
                } else {
                  localStorage.removeItem("TOU");
                  return history.push("/Unauthorised");
                }
              })
              .catch((error) => {
                localStorage.removeItem("TOU");
                return history.push("/Unauthorised");
              })
          );
        } else {
          setErrorMessage("Enddate cannot be before Startdate..!!");
        }
      } else {
        setErrorMessage("Enter Proper Data..!!");
      }
    },
    [history, advertiserId, startDate, endDate]
  );

  // const sendAdvertiserData = (values) => {
  //   setAdvertiserId(values.advertiserId);
  //   setActivePublisherArray([]);
  //   setPartiallyActivePublisherArray([])
  //   setInActivePublisherArray([]);
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setErrorMessage("");
  //   setActivePublisherArray([]);
  //   setPartiallyActivePublisherArray([])
  //   setInActivePublisherArray([])
  //   setActiveLoader(true)
  //   const value = {
  //     advertiserId: advertiserId,
  //     startDate: startDate,
  //     endDate: endDate,
  //   };
  //   if( value.startDate==="" && value.endDate===""){
  //     if(value.advertiserId==="All"){
  //       setErrorMessage("Please Enter Date..!!")
  //     }else{
  //       setErrorMessage("")
  //       trackPromise(
  //         Adv_Pub_DataService.getAllPublisher(value)
  //           .then((response) => {
  //             if(response.status===200){
  //               if (response.data.message === "") {
  //                 // response.data.data.sort((a, b) => (a.count < b.count ? 1 : -1));
  //                 setActivePublisherArray(response.data.data.active);
  //                 setPartiallyActivePublisherArray(response.data.data.partiallyactive)
  //                 setInActivePublisherArray(response.data.data.inactive)
  //                 setErrorMessage("");
  //                 setActiveLoader(false)
  //               } else {
  //                 setErrorMessage(response.data.message);
  //               }
  //             } else{
  //               localStorage.removeItem("TOU")
  //               return history.push('/Unauthorised');
  //             }
  //           })
  //           .catch((error) => {
  //             localStorage.removeItem("TOU")
  //             return history.push('/Unauthorised');
  //           })
  //       );
  //     }
  //   }else if(value.startDate!=="" && value.endDate!==""){
  //     if(new Date(value?.startDate) <= new Date(value?.endDate)){
  //       setErrorMessage("")
  //       trackPromise(
  //           Adv_Pub_DataService.getAllPublisher(value)
  //             .then((response) => {
  //               if(response.status===200){
  //                 if (response.data.message === "") {
  //                   // response.data.data.sort((a, b) => (a.count < b.count ? 1 : -1));
  //                   setActivePublisherArray(response.data.data.active);
  //                   setPartiallyActivePublisherArray(response.data.data.partiallyactive)
  //                   setInActivePublisherArray(response.data.data.inactive)
  //                   setErrorMessage("");
  //                   setActiveLoader(false)
  //                 } else {
  //                   setErrorMessage(response.data.message);
  //                 }
  //               } else{
  //                 localStorage.removeItem("TOU")
  //                 return history.push('/Unauthorised');
  //               }
  //             })
  //             .catch((error) => {
  //               localStorage.removeItem("TOU")
  //               return history.push('/Unauthorised');
  //             })
  //       );
  //     }
  //     else{
  //       setErrorMessage("Enddate cannot be before Startdate..!!")
  //     }
  //   }
  //   else{
  //     setErrorMessage("Enter Proper Data..!!")
  //   }
  // };
  const data = {
    labels: cakepublishername,
    datasets: [
      {
        data: cakepublishersale,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 2,
      },
    ],
  };

  const sortpublisherarray = (type) => {
    let sortedDataActive;
    let sortedDataPartiallyActive;
    let sortedDataInActive;

    if (type === "lastwk") {
      sortedDataActive = [...activepublisherArray].sort(
        (a, b) => b.lastweeksale - a.lastweeksale
      );
      sortedDataPartiallyActive = [...partiallyactivepublisherArray].sort(
        (a, b) => b.lastweeksale - a.lastweeksale
      );
      sortedDataInActive = [...inactivepublisherArray].sort(
        (a, b) => b.lastweeksale - a.lastweeksale
      );
    } else if (type === "threewkavg") {
      sortedDataActive = [...activepublisherArray].sort(
        (a, b) => b.lastthreeweeksale - a.lastthreeweeksale
      );
      sortedDataPartiallyActive = [...partiallyactivepublisherArray].sort(
        (a, b) => b.lastthreeweeksale - a.lastthreeweeksale
      );
      sortedDataInActive = [...inactivepublisherArray].sort(
        (a, b) => b.lastthreeweeksale - a.lastthreeweeksale
      );
    } else if (type === "sixwkavg") {
      sortedDataActive = [...activepublisherArray].sort(
        (a, b) => b.lastsixweeksale - a.lastsixweeksale
      );
      sortedDataPartiallyActive = [...partiallyactivepublisherArray].sort(
        (a, b) => b.lastsixweeksale - a.lastsixweeksale
      );
      sortedDataInActive = [...inactivepublisherArray].sort(
        (a, b) => b.lastsixweeksale - a.lastsixweeksale
      );
    } else if (type === "totalsale") {
      sortedDataActive = [...activepublisherArray].sort(
        (a, b) => b.count - a.count
      );
      sortedDataPartiallyActive = [...partiallyactivepublisherArray].sort(
        (a, b) => b.count - a.count
      );
      sortedDataInActive = [...inactivepublisherArray].sort(
        (a, b) => b.count - a.count
      );
    }
    setActivePublisherArray(sortedDataActive);
    setPartiallyActivePublisherArray(sortedDataPartiallyActive);
    setInActivePublisherArray(sortedDataInActive);
  };

  // Handle filter changes
  const handleFilterChange = (type) => {
    setSelectedFilter(type);
    // Update the array to reflect the filter changes
    if (type === "All") {
      setActivePublisherArray((prevArray) => [...prevArray]); // Reset to original array if needed
    } else {
      const activefilteredArray = activepublisherArray
        .map((pub) => ({
          ...pub,
          publishers: pub.publishers.filter(
            (p) => p.publishermothertype === type
          ),
        }))
        .filter((pub) => pub.publishers.length > 0);

      // Ensure that filtered publishers appear at the top
      const nonFilteredArrayactive = activepublisherArray.filter(
        (pub) => !pub.publishers.some((p) => p.publishermothertype === type)
      );

      const partiallyactivefilteredArray = partiallyactivepublisherArray
        .map((pub) => ({
          ...pub,
          publishers: pub.publishers.filter(
            (p) => p.publishermothertype === type
          ),
        }))
        .filter((pub) => pub.publishers.length > 0);

      // Ensure that filtered publishers appear at the top
      const nonFilteredArraypartial = partiallyactivepublisherArray.filter(
        (pub) => !pub.publishers.some((p) => p.publishermothertype === type)
      );

      setActivePublisherArray([
        ...activefilteredArray,
        ...nonFilteredArrayactive,
      ]);
      setPartiallyActivePublisherArray([
        ...partiallyactivefilteredArray,
        ...nonFilteredArraypartial,
      ]);
    }
  };

  return (
    <div>
      <Navbar />
      <form>
        <div style={{ margin: "10px" }}>
          {/* <div className="col">
                <select
                  className="form-control form-control-sm"
                  onChange={(e) => setAdvertiserId(e.target.value)}
                  value={advertiserId}
                  required
                >
                  <option value="All">All</option>
                  {advertiserArray.map((ele, index) => {
                    return (
                      <option key={index} value={ele.advertiserId}>
                        {ele.advertiserName}
                      </option>
                    );
                  })}
                </select>
              </div> */}
          <div className="form-row">
            <p class="fs-1 col">{advertiserName}</p>
            <div>Start Date</div>
            <div className="col">
              <input
                type="date"
                className="form-control"
                placeholder="YYYY-MM-DD"
                value={startDate}
                onChange={(e, data) => setStartDate(e.target.value)}
              />
            </div>
            <div>End Date</div>
            <div className="col">
              <input
                type="date"
                className="form-control"
                placeholder="YYYY-MM-DD"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
          {/* <div className="col-auto">
                <button type="submit" className="btn btn-success mb-2">
                  Search
                </button>
              </div> */}
        </div>
        <div>
          <Button
            bordered
            color="primary"
            auto
            flat
            onClick={() => setVisible(true)}
          >
            Cake
          </Button>
          <Modal
            scroll
            width="60rem"
            closeButton
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            {...bindings}
          >
            <Modal.Header>CAKE</Modal.Header>
            <Modal.Body>
              <Pie
                data={data}
                options={{ plugins: { legend: { display: false } } }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button flat auto color="error" onClick={() => setVisible(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <h5>Note: Search publisher by :: Control + F </h5>
        </div>
        {startDate === "" || endDate === "" ? (
          <div></div>
        ) : (
          <div>
            <div class="d-flex flex-row-reverse bd-highlight">
              <div class="p-2 bd-highlight">
                <Radio.Group defaultValue={state} orientation="horizontal">
                  <Radio
                    value="Active"
                    color="success"
                    labelColor="success"
                    onFocus={(e) => setState("Active")}
                  >
                    Active
                  </Radio>

                  <Radio
                    value="Partially_Active"
                    color="secondary"
                    labelColor="secondary"
                    onFocus={(e) => setState("Partially_Active")}
                  >
                    Partially Active
                  </Radio>

                  <Radio
                    value="In_Active"
                    color="error"
                    labelColor="error"
                    onFocus={(e) => setState("In_Active")}
                  >
                    In-Active
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          </div>
        )}
      </form>
      {errorMessage !== "" ? (
        <p className="font-italic" style={{ marginLeft: "10px", color: "red" }}>
          {errorMessage}
        </p>
      ) : (
        <div>
          {activeLoader ? (
            <center>
              <Grid>
                <Loading type="points" />
              </Grid>
            </center>
          ) : (
            <>
              <div>
                <div>
                  <button
                    class="btn btn-success dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {selectedFilter}
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li
                      onClick={() => handleFilterChange("All")}
                      style={{
                        paddingLeft: "1rem",
                        color: selectedFilter === "All" ? "green" : "Black",
                      }}
                    >
                      All
                    </li>
                    {allmotherpublishertype.map((type, index) => (
                      <li
                        key={index}
                        onClick={() => handleFilterChange(type)}
                        style={{
                          paddingLeft: "1rem",
                          color: selectedFilter === type ? "green" : "Black",
                        }}
                      >
                        {type}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <p className="font-italic" style={{ marginLeft: "10px" }}>
                {state === "Active"
                  ? activepublisherArray.length
                  : state === "Partially_Active"
                  ? partiallyactivepublisherArray.length
                  : inactivepublisherArray.length}{" "}
                - Publisher Found
              </p>

              <table className="table" style={{ marginLeft: "10px" }}>
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    {/* <th scope="col">Info</th> */}
                    <th scope="col">Publisher</th>
                    <th scope="col">Type</th>

                    {allnetwork[0].allnetwork.map((network, index) => {
                      return (
                        <th tabIndex={index} scope="col">
                          {network.toUpperCase()}
                        </th>
                      );
                    })}
                    <th
                      scope="col"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => sortpublisherarray("lastwk")}
                    >
                      Last WK
                    </th>
                    <th
                      scope="col"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => sortpublisherarray("threewkavg")}
                    >
                      3 WK AVG
                    </th>
                    <th
                      scope="col"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => sortpublisherarray("sixwkavg")}
                    >
                      6 WK AVG
                    </th>
                    <th
                      scope="col"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => sortpublisherarray("totalsale")}
                    >
                      Total Sales
                    </th>
                    {/* <th scope="col">Detail</th> */}
                  </tr>
                </thead>
                <tbody>
                  {state === "Active"
                    ? activepublisherArray.map((publisher, index) => {
                        return (
                          <tr
                            key={index}
                            className={
                              publisher.lastweeksale >=
                              Math.round(publisher.lastthreeweeksale / 3)
                                ? publisher.lastweeksale >
                                  1 +
                                    Math.round(publisher.lastthreeweeksale / 3)
                                  ? "table-success"
                                  : "table-warning"
                                : publisher.lastweeksale >
                                  1 -
                                    Math.round(publisher.lastthreeweeksale / 3)
                                ? "table-danger"
                                : "table-warning"
                            }
                            style={{
                              borderBlockColor: "black",
                              cursor: "pointer",
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            {/* <td>
                              <Link
                                to={{
                                  pathname: "/publisher",
                                  state: {
                                    publishermothername:
                                      publisher.publishermothername,
                                    allnetwork: allnetwork,
                                  },
                                }}
                              >
                                <div style={{ fontSize: "18px" }}>
                                  <AiFillInfoCircle />
                                </div>
                              </Link>
                            </td> */}
                            <td
                            // onClick={(e) =>
                            //   window.open(
                            //     "https://ui.awin.com/awin/merchant/" +
                            //       advertiserId +
                            //       "/affiliate-profile/" +
                            //       publisher.publisherId,
                            //     "_blank"
                            //   )
                            // }
                            >
                              <Link
                                to={{
                                  pathname: "/publisher",
                                  search: `?publishermothername=${encodeURIComponent(
                                    publisher.publishermothername
                                  )}`,
                                  // state: {
                                  //   publishermothername:
                                  //     publisher.publishermothername,
                                  //   allnetwork: allnetwork,
                                  // },
                                }}
                              >
                                <h5 style={{ color: "black" }}>
                                  {publisher.publishermothername}
                                </h5>
                              </Link>
                            </td>

                            <td>
                              <Popover placement="right-bottom">
                                <Popover.Trigger>
                                  <Button
                                    color={"white"}
                                    css={{ justifyContent: "left" }}
                                  >
                                    {publisher?.publishers[0]
                                      ?.publishermothertype || "--"}
                                  </Button>
                                </Popover.Trigger>
                                <Popover.Content>
                                  {allmotherpublishertype.map(
                                    (mothertype, index) => {
                                      return (
                                        <div
                                          tabIndex={index}
                                          style={{
                                            cursor: "cell",
                                            backgroundColor:
                                              mothertype ===
                                              publisher?.publishers[0]
                                                .publishermothertype
                                                ? "lightgreen"
                                                : "white",
                                          }}
                                          className="px-1 py-2"
                                          onClick={(e) => {
                                            publisherService.editpublishermothertype(
                                              publisher.publishermothername,
                                              mothertype
                                            );
                                            const updatedArray =
                                              activepublisherArray.map(
                                                (pub) => {
                                                  if (
                                                    pub.publishermothername ===
                                                    publisher.publishermothername
                                                  ) {
                                                    return {
                                                      ...pub,
                                                      publishers:
                                                        pub.publishers.map(
                                                          (p) => ({
                                                            ...p,
                                                            publishermothertype:
                                                              mothertype,
                                                          })
                                                        ),
                                                    };
                                                  }
                                                  return pub;
                                                }
                                              );

                                            setActivePublisherArray(
                                              updatedArray
                                            );
                                          }}
                                        >
                                          {mothertype !== null
                                            ? mothertype
                                            : "-"}
                                        </div>
                                      );
                                    }
                                  )}
                                </Popover.Content>
                              </Popover>
                            </td>

                            {allnetwork[0].allnetwork.map((network, index) => {
                              return publisher.networks.some(
                                (n) => n === network
                              ) ? (
                                <td tabIndex={index}>
                                  <AiOutlineCheck
                                    color={
                                      publisher.publishers.some(
                                        (pub) => pub.network === network
                                      )
                                        ? "green"
                                        : "red"
                                    }
                                  />
                                </td>
                              ) : (
                                <td tabIndex={index}>-</td>
                              );
                            })}
                            <td>{publisher.lastweeksale}</td>
                            <td>
                              {publisher.lastthreeweeksale === "-"
                                ? "-"
                                : Math.round(publisher.lastthreeweeksale / 3)}
                            </td>
                            <td>
                              {publisher.lastsixweeksale === "-"
                                ? "-"
                                : Math.round(publisher.lastsixweeksale / 6)}
                            </td>
                            <td>{publisher.count}</td>
                            {/* <td>
                              <Detail
                                // sendAdvertiserData={sendAdvertiserData}
                                publisherName={publisher.publisherName}
                                publisherId={publisher.publisherId}
                                startDate={startDate}
                                endDate={endDate}
                              />
                            </td> */}
                          </tr>
                        );
                      })
                    : state === "Partially_Active"
                    ? partiallyactivepublisherArray.map((publisher, index) => {
                        return (
                          <tr
                            key={index}
                            className={
                              publisher.lastweeksale >=
                              Math.round(publisher.lastthreeweeksale / 3)
                                ? publisher.lastweeksale >
                                  1 +
                                    Math.round(publisher.lastthreeweeksale / 3)
                                  ? "table-success"
                                  : "table-warning"
                                : publisher.lastweeksale >
                                  1 -
                                    Math.round(publisher.lastthreeweeksale / 3)
                                ? "table-danger"
                                : "table-warning"
                            }
                            style={{
                              borderBlockColor: "black",
                              cursor: "pointer",
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <td
                            //   onClick={(e) =>
                            //     window.open(
                            //       "https://ui.awin.com/awin/merchant/" +
                            //         advertiserId +
                            //         "/affiliate-profile/" +
                            //         publisher.publisherId,
                            //       "_blank"
                            //     )
                            //   }
                            >
                              <Link
                                to={{
                                  pathname: "/publisher",
                                  search: `?publishermothername=${publisher.publishermothername}`,
                                }}
                              >
                                <h5 style={{ color: "black" }}>
                                  {publisher.publishermothername}
                                </h5>
                              </Link>
                            </td>
                            <td>
                              <Popover placement="right-bottom">
                                <Popover.Trigger>
                                  <Button
                                    color={"white"}
                                    css={{ justifyContent: "left" }}
                                  >
                                    {publisher?.publishers[0]
                                      .publishermothertype &&
                                    publisher?.publishers[0].publishermothertype
                                      ? publisher?.publishers[0]
                                          .publishermothertype
                                      : "--"}
                                  </Button>
                                </Popover.Trigger>
                                <Popover.Content>
                                  {allmotherpublishertype.map(
                                    (mothertype, index) => {
                                      return (
                                        <div
                                          tabIndex={index}
                                          style={{
                                            cursor: "cell",
                                            backgroundColor:
                                              mothertype ===
                                              publisher?.publishers[0]
                                                .publishermothertype
                                                ? "lightgreen"
                                                : "white",
                                          }}
                                          className="px-1 py-2"
                                          onClick={(e) => {
                                            publisherService.editpublishermothertype(
                                              publisher.publishermothername,
                                              mothertype
                                            );

                                            const updatedArray =
                                              partiallyactivepublisherArray.map(
                                                (pub) => {
                                                  if (
                                                    pub.publishermothername ===
                                                    publisher.publishermothername
                                                  ) {
                                                    return {
                                                      ...pub,
                                                      publishers:
                                                        pub.publishers.map(
                                                          (p) => ({
                                                            ...p,
                                                            publishermothertype:
                                                              mothertype,
                                                          })
                                                        ),
                                                    };
                                                  }
                                                  return pub;
                                                }
                                              );

                                            setActivePublisherArray(
                                              updatedArray
                                            );
                                          }}
                                        >
                                          {mothertype !== null
                                            ? mothertype
                                            : "-"}
                                        </div>
                                      );
                                    }
                                  )}
                                </Popover.Content>
                              </Popover>
                            </td>
                            {allnetwork[0].allnetwork.map((network, index) => {
                              return publisher.networks.some(
                                (n) => n === network
                              ) ? (
                                <td tabIndex={index}>
                                  <AiOutlineCheck
                                    color={
                                      publisher.publishers.some(
                                        (pub) => pub.network === network
                                      )
                                        ? "green"
                                        : "red"
                                    }
                                  />
                                </td>
                              ) : (
                                <td tabIndex={index}>-</td>
                              );
                            })}
                            <td>{publisher.lastweeksale}</td>
                            <td>
                              {publisher.lastthreeweeksale === "-"
                                ? "-"
                                : Math.round(publisher.lastthreeweeksale / 3)}
                            </td>
                            <td>
                              {publisher.lastsixweeksale === "-"
                                ? "-"
                                : Math.round(publisher.lastsixweeksale / 6)}
                            </td>
                            <td>{publisher.count}</td>
                            {/* <td>
                              <Detail
                                // sendAdvertiserData={sendAdvertiserData}
                                publisherName={publisher.publisherName}
                                publisherId={publisher.publisherId}
                                startDate={startDate}
                                endDate={endDate}
                              />
                            </td> */}
                          </tr>
                        );
                      })
                    : inactivepublisherArray.map((publisher, index) => {
                        return (
                          <tr
                            key={index}
                            className={
                              publisher.lastweeksale >=
                              Math.round(publisher.lastthreeweeksale / 3)
                                ? publisher.lastweeksale >
                                  1 +
                                    Math.round(publisher.lastthreeweeksale / 3)
                                  ? "table-success"
                                  : "table-warning"
                                : publisher.lastweeksale >
                                  1 -
                                    Math.round(publisher.lastthreeweeksale / 3)
                                ? "table-danger"
                                : "table-warning"
                            }
                            style={{
                              borderBlockColor: "black",
                              cursor: "pointer",
                            }}
                          >
                            <th scope="row">{index + 1}</th>

                            <td
                            // onClick={(e) =>
                            //   window.open(
                            //     "https://ui.awin.com/awin/merchant/" +
                            //       advertiserId +
                            //       "/affiliate-profile/" +
                            //       publisher.publisherId,
                            //     "_blank"
                            //   )
                            // }
                            >
                              <Link
                                to={{
                                  pathname: "/publisher",
                                  state: {
                                    publishermothername:
                                      publisher.publishermothername,
                                    allnetwork: allnetwork,
                                  },
                                }}
                              >
                                <h5 style={{ color: "black" }}>
                                  {publisher.publishermothername}
                                </h5>
                              </Link>
                            </td>
                            <td>
                              <Popover placement="right-bottom">
                                <Popover.Trigger>
                                  <Button
                                    color={"white"}
                                    css={{ justifyContent: "left" }}
                                  >
                                    {publisher?.publishers[0]
                                      .publishermothertype &&
                                    publisher?.publishers[0].publishermothertype
                                      ? publisher?.publishers[0]
                                          .publishermothertype
                                      : "--"}
                                  </Button>
                                </Popover.Trigger>
                                <Popover.Content>
                                  {allmotherpublishertype.map(
                                    (mothertype, index) => {
                                      return (
                                        <div
                                          tabIndex={index}
                                          style={{
                                            cursor: "cell",
                                            backgroundColor:
                                              mothertype ===
                                              publisher?.publishers[0]
                                                .publishermothertype
                                                ? "lightgreen"
                                                : "white",
                                          }}
                                          className="px-1 py-2"
                                          onClick={(e) => {
                                            publisherService.editpublishermothertype(
                                              publisher.publishermothername,
                                              mothertype
                                            );

                                            const updatedArray =
                                              inactivepublisherArray.map(
                                                (pub) => {
                                                  if (
                                                    pub.publishermothername ===
                                                    publisher.publishermothername
                                                  ) {
                                                    return {
                                                      ...pub,
                                                      publishers:
                                                        pub.publishers.map(
                                                          (p) => ({
                                                            ...p,
                                                            publishermothertype:
                                                              mothertype,
                                                          })
                                                        ),
                                                    };
                                                  }
                                                  return pub;
                                                }
                                              );

                                            setActivePublisherArray(
                                              updatedArray
                                            );
                                          }}
                                        >
                                          {mothertype !== null
                                            ? mothertype
                                            : "-"}
                                        </div>
                                      );
                                    }
                                  )}
                                </Popover.Content>
                              </Popover>
                            </td>
                            <td>-</td>
                            <td>-</td>
                            <td>{publisher.lastweeksale}</td>
                            <td>
                              {publisher.lastthreeweeksale === "-"
                                ? "-"
                                : Math.round(publisher.lastthreeweeksale / 3)}
                            </td>
                            <td>
                              {publisher.lastsixweeksale === "-"
                                ? "-"
                                : Math.round(publisher.lastsixweeksale / 6)}
                            </td>
                            <td>{publisher.count}</td>
                            {/* <td>
                              <Detail
                                // sendAdvertiserData={sendAdvertiserData}
                                publisherName={publisher.publisherName}
                                publisherId={publisher.publisherId}
                                startDate={startDate}
                                endDate={endDate}
                              />
                            </td> */}
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default Adv_Pub;
