import React, { useState, useEffect } from "react";
import { Modal } from "@nextui-org/react";
import { Form, Button, Row, Col } from "react-bootstrap";
import Select from "react-select";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import { BiEdit } from "react-icons/bi";
import campaignService from "../Services/campaignService";

export default function AddCampaign(props) {
  const campaignId = props?.campaign?._id;

  const [formData, setFormData] = useState({
    network: "",
    advertiser: "",
    startDate: "",
    endDate: "",
    type: "",
    title: "",
    description: "",
    selectedPublishers: [],
  });
  const alladvertiserArray = props.alladvertiserArray;

  const [visible, setVisible] = useState(false);

  const allnetwork = props.allnetwork;
  const allpublisher = props.allpublisher;
  const [publisherArray, setPublisherArray] = useState([]);
  const [advertiserArray, setAdvertiserArray] = useState(
    props.alladvertiserArray
  );

  useEffect(
    (e) => {
      if (props.campaign) {
        const filteredAdvertisers = alladvertiserArray.filter(
          (advertiser) =>
            advertiser.network === props.campaign.network && advertiser.isActive
        );
        setAdvertiserArray(filteredAdvertisers);

        const filteredPublishers = allpublisher.filter(
          (publisher) =>
            publisher.network === props.campaign.network &&
            publisher.connectedAdvertiser.some(
              (advertiser) =>
                Number(advertiser.advertiserId) ===
                Number(props.campaign.advertiser)
            )
        );
        setPublisherArray(filteredPublishers);

        setFormData({
          network: props.campaign.network,
          advertiser: props.campaign.advertiser,
          startDate: moment(props.campaign.startDate).format("YYYY-MM-DD"),
          endDate: moment(props.campaign.endDate).format("YYYY-MM-DD"),
          type: props.campaign.type,
          title: props.campaign.title,
          description: props.campaign.description,
          selectedPublishers: props.campaign.selectedPublishers,
        });
      }
    },
    [props.campaign, alladvertiserArray, allpublisher]
  );

  const handler = () => setVisible(true);
  const closeHandler = () => {
    setVisible(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "network") {
      const filteredAdvertisers = alladvertiserArray.filter(
        (advertiser) => advertiser.network === value && advertiser.isActive
      );
      setAdvertiserArray(filteredAdvertisers);
    }

    if (name === "advertiser") {
      const filteredPublishers = allpublisher.filter(
        (publisher) =>
          publisher.network === formData.network &&
          publisher.connectedAdvertiser.some(
            (advertiser) => Number(advertiser.advertiserId) === Number(value)
          )
      );
      setPublisherArray(filteredPublishers);
    }
  };

  const handlePublisherChange = (selectedOptions) => {
    setFormData({ ...formData, selectedPublishers: selectedOptions });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (campaignId && campaignId) {
      await campaignService
        .editCampaign(campaignId, formData)
        .then((response) => {
          if (response.status === 200) {
            props.updatcampaign(formData, campaignId);
            closeHandler();
          }
        });
    } else {
      await campaignService.insertCampaign(formData).then((response) => {
        if (response.status === 200) {
          props.updatcampaign(response.data.insertedData[0], null);
          setFormData({
            network: "",
            advertiser: "",
            startDate: "",
            endDate: "",
            type: "",
            title: "",
            description: "",
            selectedPublishers: [],
          });
          closeHandler();
        }
      });
    }
  };

  return (
    <div>
      {campaignId && campaignId ? (
        <BiEdit onClick={handler} />
      ) : (
        <Button onClick={handler} variant="success" style={{ margin: "0.5%" }}>
          Add Campaign
        </Button>
      )}

      <Modal
        closeButton
        animated={true}
        width={"70rem"}
        blur
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
      >
        <Modal.Header>
          <h3>Generate Campaign</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="container mt-9">
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="network">
                <Form.Label>Network*</Form.Label>
                <Form.Control
                  as="select"
                  name="network"
                  value={formData.network}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Network</option>
                  {allnetwork.map((network, index) => (
                    <option key={index} value={network} tabIndex={index}>
                      {network.toUpperCase()}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="advertiser">
                <Form.Label>Advertiser*</Form.Label>
                <Form.Control
                  as="select"
                  name="advertiser"
                  value={formData.advertiser}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Advertiser</option>
                  {advertiserArray.map((adv, index) => (
                    <option
                      key={index}
                      value={adv.advertiserId}
                      tabIndex={index}
                    >
                      {adv.advertiserName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="publishers">
                <Form.Label>Target Publishers</Form.Label>
                <Select
                  isMulti
                  name="publishers"
                  options={publisherArray.map((publisher) => ({
                    value: publisher.publisherId,
                    label: publisher.publisherName,
                  }))}
                  value={formData.selectedPublishers}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handlePublisherChange}
                />
                <h6 style={{ color: "red" }}>
                  If you not select any publisher then it will consider all
                  publishers for selected Advertiser.
                </h6>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col>
                    <Form.Label>Start Date*</Form.Label>
                    <Form.Control
                      type="date"
                      name="startDate"
                      value={formData.startDate}
                      onChange={handleChange}
                      required
                    />
                  </Col>
                  <Col>
                    <Form.Label>End Date*</Form.Label>
                    <Form.Control
                      type="date"
                      name="endDate"
                      value={formData.endDate}
                      onChange={handleChange}
                      required
                      min={formData.startDate}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group controlId="type">
                <Form.Label>Type*</Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Type</option>
                  <option value="Exclusive coupon code">
                    Exclusive coupon code
                  </option>
                  <option value="General coupon code">
                    General coupon code
                  </option>
                  <option value="CPAi">CPAi</option>
                  <option value="Fixed fee">Fixed fee</option>
                  <option value="Exclusive coupon & CPAi">
                    Exclusive coupon & CPAi
                  </option>
                  <option value="Exclusive coupon & Fixed fee">
                    Exclusive coupon & Fixed fee
                  </option>
                  <option value="CPAi & Fixed fee">CPAi & Fixed fee</option>
                  <option value="Exclusive coupon & CPAi & Fixed fee">
                    Exclusive coupon & CPAi & Fixed fee
                  </option>
                  <option value="Other">Other</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="title">
                <Form.Label>Title*</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="description">
                <Form.Label>Description*</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <br />
              <Button variant="danger" onClick={closeHandler}>
                Close
              </Button>{" "}
              {campaignId && campaignId ? (
                <Button variant="success" type="submit">
                  Edit Campaign
                </Button>
              ) : (
                <Button variant="success" type="submit">
                  Generate Campaign
                </Button>
              )}
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
