import http from "./Http-Common";

class PublisherService {
  insertcontactperson(publishermothername, publishercontact) {
    return http.post("/publisher/insertcontactperson", {
      publishermothername,
      publishercontact,
    });
  }

  getallnetwork() {
    return http.post("/publisher/getallnetwork");
  }

  getallpublisher() {
    return http.post("/publisher/getallpublisher");
  }

  getallpublishertype() {
    return http.post("/publisher/getallpublishertype");
  }

  editpublishertype(publisherId, pubtype, mothertype) {
    return http.post(`/publisher/changetype/${publisherId}`, {
      pubtype,
      mothertype,
    });
  }

  connectpublisher(publisherId, publishermothername) {
    return http.post(`/publisher/connect/${publisherId}`, {
      publishermothername,
    });
  }

  removepublisher(publisherId) {
    return http.post(`/publisher/remove/${publisherId}`);
  }

  getconnectedpublisher(publishermothername, advertiserfilter) {
    return http.post("/publisher/getconnectedpublisher", {
      publishermothername,
      advertiserfilter,
    });
  }

  searchpublisher(searchQuery) {
    return http.post(`/publisher/search?query=${searchQuery}`);
  }

  editpublishermothername(publishermothername, temppublishermothername) {
    return http.post("/publisher/edit/publishermothername", {
      publishermothername,
      temppublishermothername,
    });
  }

  editpublishermothertype(publishermothername, publishermothertype) {
    return http.post("/publisher/edit/publishermothertype", {
      publishermothername,
      publishermothertype,
    });
  }

  insertpublishernote(publishermothername, advertiserArray, notedetail) {
    return http.post(`/publisher/insert/publishernote/${publishermothername}`, {
      advertiserArray,
      notedetail,
    });
  }

  getpublishernote(publishermothername) {
    return http.post(`/publisher/get/publishernote/${publishermothername}`);
  }

  updatepublishernote(
    publishermothername,
    publishernoteId,
    notedetail,
    advertiserArray
  ) {
    return http.post(`/publisher/update/publishernote/${publishermothername}`, {
      publishernoteId,
      notedetail,
      advertiserArray,
    });
  }

  getAdvPubNote(advertiserid) {
    return http.post(
      `/publisher/get/publishernotebyadvertiser/${advertiserid}`
    );
  }
}

export default new PublisherService();
