import http from "./Http-Common";

class CampaignService {
  insertCampaign(formData) {
    return http.post("/campaign/insertcampaign", formData);
  }

  editCampaign(campaignid, formData) {
    return http.post(`/campaign/editcampaign/${campaignid}`, formData);
  }

  deletecampaign(campaignid) {
    return http.post(`/campaign/deletecampaign/${campaignid}`);
  }

  getallCampaign(page, filterCriteria, column, order) {
    return http.post(
      `/campaign/getcampaign?page=${page}&limit=10&sortColumn=${column}&sortOrder=${order}`,
      filterCriteria
    );
  }

  getcampaignbyid(campaignid) {
    return http.post(`/campaign/getcampaignbyid/${campaignid}`);
  }

  generatereport(campaignid) {
    return http.post(`/campaign/campaignreport/${campaignid}`);
  }
}
export default new CampaignService();
